import React from 'react'
import { Row } from 'react-table'

import { Data } from '../types'
import OrderDetail from './order-detail'
import TransactionDetail from './transaction-detail'

export const renderDetails = ({ original }: Row<Data>) => {
  switch (original.type) {
    case 'transaction':
      return <TransactionDetail data={original.detail} />
    default:
      return <OrderDetail data={original.detail} />
  }
}
