import { em } from 'polished'
import React from 'react'

import { underlineButton } from '~components/filter-select/mixins'
import { useTableContext } from '~components/table'
import { useMedia } from '~hooks/use-media'
import { AccountDetails } from '~models/portfolio'
import { getQuery, mq, styled } from '~styles'
import { triangle } from '~styles/mixins'
import AccountFilter from './account-filter'
import AssetFilter from './asset-filter'
import DateFilter from './date-filter'
import TypeFilter from './type-filter'

export interface Props {
  accountColumnId: string
  accountDetailsData: AccountDetails[]
  assetColumnId: string
  dateColumnId: string
  typeColumnId: string
}

const FiltersButton = styled.button`
  ${underlineButton}
  display: flex;
  width: 100%;

  ${mq('md')} {
    display: none;
  }
`

const ButtonLabel = styled.span`
  flex: 1;
  font-size: ${em(12)};
  font-weight: 600;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
`

const Triangle = styled.span`
  &::after {
    ${({ theme }) => triangle(theme.grayLighter)}
  }
`

const Row = styled.div`
  ${mq('md')} {
    display: flex;
    flex-wrap: nowrap;
  }
`

const Column = styled.div`
  flex: 1;

  > [data-reach-listbox-input] {
    margin-top: 12px;
  }

  ${mq('md')} {
    &:not(:last-child) {
      margin-right: 18px;
    }
    &:not(:first-of-type) {
      margin-left: 18px;
    }
  }
`

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.linkColor};
  cursor: pointer;
  display: inline-block;
  font-size: ${em(11)};
  font-weight: 600;
  letter-spacing: 1px;
  padding: 4px;
  text-transform: uppercase;
`

const byColumnId = (columnId: string) => (filter: { id: string }) =>
  filter.id === columnId

const Header: React.FC<Props> = ({
  accountColumnId,
  accountDetailsData,
  assetColumnId,
  dateColumnId,
  typeColumnId,
  ...props
}) => {
  const { setAllFilters, setSortBy, state } = useTableContext()

  const initialShowFilters = useMedia([getQuery('md')], [true], false)
  const [showFilters, setShowFilters] = React.useState(initialShowFilters)

  const isFiltered = state.filters.some(f => !byColumnId(accountColumnId)(f))

  const handleReset = () => {
    setAllFilters(filters => filters.filter(byColumnId(accountColumnId)))
    setSortBy([{ id: dateColumnId, desc: true }])
  }

  return (
    <div {...props}>
      <AccountFilter data={accountDetailsData} columnId={accountColumnId} />
      <FiltersButton onClick={() => setShowFilters(!showFilters)}>
        <ButtonLabel>Filters</ButtonLabel>
        <Triangle />
      </FiltersButton>
      {showFilters && (
        <Row>
          <Column>
            <AssetFilter columnId={assetColumnId} />
          </Column>
          <Column>
            <TypeFilter columnId={typeColumnId} />
          </Column>
          <Column>
            <DateFilter columnId={dateColumnId} />
          </Column>
        </Row>
      )}
      {isFiltered && (
        <div css={{ marginTop: 10, textAlign: 'right' }}>
          <LinkButton onClick={handleReset}>Reset Filters</LinkButton>
        </div>
      )}
    </div>
  )
}

export default Header
