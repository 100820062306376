import { rem } from 'polished'
import React from 'react'
import { CellProps, Column, ColumnInstance, Meta } from 'react-table'

import { css, styled } from '~styles'
import { Data } from './types'

export interface ExpanderProps {
  isActive?: boolean
}

const Expander = styled.span<ExpanderProps>`
  color: ${({ theme }) => theme.blue};
  font-size: ${rem(8)};
  line-height: 1;
  padding: 5px;
  transition: transform 150ms ease;

  &::after {
    content: '▲';
  }

  ${({ isActive = false }) =>
    isActive &&
    css`
      transform: rotate(180deg);
    `}
`

export const useExpanderColumn = (
  columns: ColumnInstance<Data>[],
  { instance }: Meta<Data>,
) => {
  if (instance.state.groupBy.length === 0) {
    return columns
  }

  const expanderColumn: Column<Data> = {
    id: 'expander',
    align: 'right',
    minWidth: 20,
    width: 20,
    Cell: ({ row }: CellProps<Data>) =>
      row.canExpand ? (
        <Expander
          isActive={row.isExpanded}
          {...row.getToggleRowExpandedProps()}
        />
      ) : null, // eslint-disable-line unicorn/no-null
  }

  return [...columns, expanderColumn]
}
