import { CSSObject, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { forwardRef, useRef, useState } from 'react'

import { CustomTheme } from '~styles'
import { textNoWrap } from '~styles/mixins'
import { mergeRefs } from '~utils/react'

type FileInputColor = 'default' | 'danger' | 'success'

type InputElementProps = React.InputHTMLAttributes<HTMLInputElement>

export interface FileInputProps
  extends Pick<InputElementProps, 'accept' | 'id' | 'name'> {
  color?: FileInputColor
  isDisabled?: boolean
  isRequired?: boolean
  label?: string
  onChange?: (value: File) => void
  testId?: string
}

interface RootProps {
  color?: FileInputColor
  isDisabled?: boolean
  isDragging: boolean
}

const fileInputColors = (
  theme: CustomTheme,
): Record<FileInputColor, CSSObject> => ({
  default: {
    borderColor: theme.grayLighter,
  },
  danger: {
    borderColor: theme.danger,
  },
  success: {
    borderColor: theme.success,
  },
})

const disabled = (theme: CustomTheme) => css`
  background-color: ${theme.whiteBis};
  color: ${theme.textLightColor};
  cursor: not-allowed;
`

const dragging = (theme: CustomTheme) => css`
  background-color: ${theme.grayLightest};
`

const Root = styled.div<RootProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border: 2px dashed transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 30px 25px 25px;
  position: relative;
  ${({ color = 'default', theme }) => fileInputColors(theme)[color]};
  ${({ isDragging, theme }) => isDragging && dragging(theme)};
  ${({ isDisabled, theme }) => isDisabled && disabled(theme)};
`

const Label = styled.label`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
`

const Span = styled.span`
  ${textNoWrap}
  max-width: 254px;
`

const Overlay = styled.div<{ isDisabled?: boolean }>`
  height: 100%;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      accept,
      color,
      id,
      isDisabled,
      isRequired,
      label: labelText = 'Select File',
      name,
      onChange,
      testId,
      ...props
    },
    ref,
  ) => {
    const [file, setFile] = useState<File>()
    const [isDragging, setDragging] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]

      if (file) {
        setFile(file)
        onChange?.(file)
      }
    }

    const handleDrag =
      ({ isEnter }: { isEnter: boolean }) =>
      (e: React.DragEvent) => {
        e.preventDefault()
        setDragging(isEnter)
      }

    const handleDrop = (e: React.DragEvent) => {
      e.stopPropagation()
      e.preventDefault()
      setDragging(false)

      const file = e.dataTransfer?.files?.[0]

      if (file) {
        setFile(file)
        onChange?.(file)
      }
    }

    return (
      <Root
        color={color}
        isDisabled={isDisabled}
        isDragging={isDragging}
        {...props}
      >
        <input
          accept={accept}
          disabled={isDisabled}
          id={id}
          name={name}
          onChange={handleChange}
          ref={mergeRefs(inputRef, ref)}
          required={isRequired}
          style={{ display: 'none' }}
          type="file"
          data-testid={testId}
        />
        <Label htmlFor={id}>{labelText}</Label>
        {file && <Span>{file.name}</Span>}
        <Overlay
          isDisabled={isDisabled}
          onClick={() => inputRef.current?.click()}
          onDragEnter={handleDrag({ isEnter: true })}
          onDragLeave={handleDrag({ isEnter: false })}
          onDragOver={e => e.preventDefault()}
          onDrop={handleDrop}
        />
      </Root>
    )
  },
)
FileInput.displayName = 'FileInput'

export { FileInput }
