import { format, parseISO } from 'date-fns'
import React from 'react'

import { assetTypeLabels, orderTypeLabels } from '~constants/labels'
import { AssetType } from '~models/common'
import { Order, Trade } from '~models/trade'
import { styled } from '~styles'
import { formatCurrency } from '~utils/format'
import { card, cell, grid, label, value } from './mixins'

export interface Props {
  data: { order: Order; tradeId: number }
}

const Root = styled.div`
  ${card}
`

const Grid = styled.div`
  ${grid}
`

const Cell = styled.span`
  ${cell}
`

const Label = styled.div`
  ${label}
`

const Value = styled.div`
  ${value}
`

const OrderDetail: React.FC<Props> = ({ data, ...props }) => {
  const { order, tradeId } = data
  const {
    createdDateTime,
    custodianAccountNumber,
    custodianFee,
    platformFee,
    trades,
  } = order

  const { assetType, tradeType, volume } = trades.find(
    t => t.tradeId === tradeId,
  ) as Trade

  const cells = [
    { label: 'Account Number', value: custodianAccountNumber },
    {
      label: 'Transaction Date',
      value: format(parseISO(createdDateTime), 'yyyy-MM-dd HH:mm:ss'),
    },
    { label: 'Transaction Type', value: orderTypeLabels[tradeType] },
    {
      label: 'Asset',
      value: `${AssetType[assetType]} - ${assetTypeLabels[assetType]}`,
    },
    { label: 'Units', value: volume },
    { label: 'Custodian Fee', value: formatCurrency(custodianFee) },
    { label: 'Platform Fee', value: formatCurrency(platformFee) },
    { label: 'Note', value: '' },
  ]

  return (
    <Root {...props}>
      <Grid>
        {cells.map(({ label, value }) => (
          <Cell key={label}>
            <Label>{label}</Label>
            <Value>{value}</Value>
          </Cell>
        ))}
      </Grid>
    </Root>
  )
}

export default OrderDetail
