import React from 'react'

import CustodianAccountSelect, {
  Props as CustodianAccountSelectProps,
} from '~components/custodian-account-select'
import { useTableContext } from '~components/table'

interface Props extends CustodianAccountSelectProps {
  columnId: string
}

const AccountFilter: React.FC<Props> = ({
  columnId,
  data,
  defaultValue,
  ...props
}) => {
  const { setFilter, state } = useTableContext()

  const filter = state.filters.find(({ id }) => id === columnId)
  const filterValue = filter?.value as string

  const handleChange = (val?: string) => setFilter(columnId, val)

  return (
    <CustodianAccountSelect
      data={data}
      defaultValue={filterValue}
      onChange={handleChange}
      {...props}
    />
  )
}

export default AccountFilter
