import {
  ListboxInput,
  ListboxInputProps,
  ListboxList,
  ListboxOption,
  ListboxPopover as BaseListboxPopover,
} from '@reach/listbox'
import styles from '@reach/listbox/styles.css'
import { positionMatchWidth } from '@reach/popover'
import useStyles from 'isomorphic-style-loader/useStyles'
import { hsla } from 'polished'
import React, { forwardRef } from 'react'

import { styled } from '~styles'
import Button, { Props as ButtonProps } from './button'
import { placeholder } from './mixins'

export interface Props
  extends ButtonProps,
    Pick<ListboxInputProps, 'defaultValue' | 'onChange' | 'value'> {
  className?: string
  placeholder?: string
}

const ListboxPopover = styled(BaseListboxPopover)`
  border-bottom-left-radius: ${({ theme }) => theme.borderRadiusLarge};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadiusLarge};
  box-shadow: 0 1px 3px 0 ${hsla(0, 0, 0, 0.07)};
  padding: 16px 0;
  z-index: 10;

  &:focus-within {
    box-shadow: 0 1px 3px 0 ${hsla(0, 0, 0, 0.14)};
    outline: none;
  }
`

const PlaceholderOption = styled(ListboxOption)`
  ${placeholder}
  letter-spacing: 1px;
  padding: 4px 20px;

  &[data-current] {
    font-weight: 600;
  }
`

const FilterSelect = forwardRef<HTMLSpanElement, Props>(function FilterSelect(
  {
    children,
    className,
    defaultValue = 'default',
    onChange,
    onReset,
    placeholder,
    value: controlledValue,
    ...props
  },
  ref,
) {
  useStyles(styles)

  const [value, setValue] = React.useState(defaultValue)

  React.useEffect(() => {
    if (controlledValue) {
      setValue(controlledValue)
    }
  }, [controlledValue])

  const handleChange = React.useCallback(
    (val: string) => {
      setValue(val)
      onChange?.(val)
    },
    [onChange],
  )

  const handleReset = React.useCallback(() => {
    setValue(defaultValue)
    onReset?.()
  }, [defaultValue, onReset])

  return (
    <ListboxInput
      className={className}
      defaultValue={defaultValue}
      onChange={handleChange}
      value={value}
    >
      <Button ref={ref} onReset={handleReset} {...props} />
      <ListboxPopover as="div" position={positionMatchWidth}>
        <ListboxList>
          {placeholder && (
            <PlaceholderOption as="li" value="default">
              {placeholder}
            </PlaceholderOption>
          )}
          {children}
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  )
})
FilterSelect.displayName = 'FilterSelect'

export default FilterSelect

export { FilterOption } from './option'
