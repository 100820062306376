import React from 'react'

import {
  AssetBitcoinIcon,
  AssetCashIcon,
  AssetCompanyIcon,
  AssetNoteIcon,
  AssetMetalIcon,
} from '~assets/icons'
import { styled } from '~styles'

type IconProps = React.PropsWithoutRef<React.SVGAttributes<SVGSVGElement>> &
  React.RefAttributes<SVGSVGElement>

interface Props {
  assetCategory?: string
}

const Root = styled.span<{ assetCategory: string }>`
  align-items: center;
  background-color: ${({ assetCategory, theme }) =>
    /cash/i.test(assetCategory) ? '#1fb22b' : theme.blue};
  border-radius: 6px;
  color: ${({ theme }) => theme.white};
  display: inline-flex;
  justify-content: center;
  height: 18px;
  width: 18px;
  z-index: 1;
`

export const renderAssetIcon = (assetCategory: string, props?: IconProps) => {
  switch (true) {
    case /note/i.test(assetCategory):
      return <AssetNoteIcon {...props} />
    case /cash/i.test(assetCategory):
      return <AssetCashIcon {...props} />
    case /metal/i.test(assetCategory):
      return <AssetMetalIcon {...props} />
    case /(company|companies|business|llc|real estate)/i.test(assetCategory):
      return <AssetCompanyIcon {...props} />
    default:
      return <AssetBitcoinIcon {...props} />
  }
}

const AggregateIcon: React.FC<Props> = ({
  assetCategory = 'crypto',
  ...props
}) => (
  <Root assetCategory={assetCategory} {...props}>
    {renderAssetIcon(assetCategory)}
  </Root>
)

export default AggregateIcon
