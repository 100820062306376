import { FC } from 'react'

import { styled } from '~styles'
import { UploadInformation } from './upload-information'
import { CardEmployeeIcon, CardEmployeeIconMSDIRA } from '~assets/icons'
import { DocumentType } from '~models/document-type'
import UploadFile from './upload-file'
import { loadConfig } from '~config.client'

const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  div:first-of-type {
    margin-right: 20px;
  }
`
const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const TitleContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`
const Title = styled.h4`
  font-size: 16px;
`
const Description = styled.p`
  font-size: 10px;
  color: #666666;
`

const UploadDocuments: FC = () => {
  return (
    <>
      <UploadInformation
        icon={
          loadConfig().siteTheme === 'msdira' ? (
            <CardEmployeeIconMSDIRA />
          ) : (
            <CardEmployeeIcon css={{ width: 40, paddingLeft: '2px' }} />
          )
        }
        title="Upload Your ID"
        description="Please upload the front and back of your Government Issued Driver's License or Passport. Make sure the images are clear and not blurry."
      />
      <FieldContainer>
        <Div>
          <UploadFile
            label="Upload Front"
            fileType={DocumentType.DriversLicense}
            name="licenseFront"
          />
        </Div>
        <Div>
          <UploadFile
            label="Upload Back"
            fileType={DocumentType.DriversLicense}
            name="licenseBack"
          />
        </Div>
      </FieldContainer>
      <TitleContainer>
        <Title>Tell Us More About Your Current Retirement Account</Title>
        <Description>
          To provide a seamless transfer of assets, please provide a recent
          statement from your current retirement account. Please make sure the
          PDF or image is clear and not blurry.
        </Description>
      </TitleContainer>
      <UploadFile
        label="Upload Statement"
        fileType={DocumentType.Statement}
        name="retirementStatement"
      />
    </>
  )
}

export default UploadDocuments
