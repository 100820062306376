import {
  Interval,
  endOfToday,
  endOfYear,
  startOfQuarter,
  startOfToday,
  startOfYear,
  subDays,
  subYears,
} from 'date-fns'

export type DateIntervalId =
  | 'last30'
  | 'last90'
  | 'quarterToDate'
  | 'yearToDate'
  | 'lastYear'

export type DateInterval = Interval

export const getStartDate = (id: DateIntervalId) => {
  const today = startOfToday()

  switch (id) {
    case 'last30':
      return subDays(today, 30)
    case 'last90':
      return subDays(today, 90)
    case 'quarterToDate':
      return startOfQuarter(today)
    case 'yearToDate':
      return startOfYear(today)
    case 'lastYear':
      return subYears(startOfYear(today), 1)
  }
}

export const getEndDate = (id: DateIntervalId) => {
  const today = endOfToday()

  switch (id) {
    case 'lastYear':
      return subYears(endOfYear(today), 1)
    default:
      return today
  }
}

export const getDateInterval = (id: DateIntervalId): DateInterval => ({
  start: getStartDate(id),
  end: getEndDate(id),
})
