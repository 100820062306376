import { z } from 'zod'

import { castToBoolean } from '~utils/validation'

const agreementSchema = z.preprocess(
  castToBoolean,
  z.boolean({ required_error: 'Agreement is required' }),
)

export const formSchema = z.object({
  privacyPolicy: agreementSchema,
  smsOptIn: agreementSchema,
  custodialTerms: agreementSchema,
  importantInformation: agreementSchema,
  eSignature: z
    .string({
      required_error: 'Signature is required',
    })
    .min(1, 'Signature is required')
    .regex(/^[\sA-Za-z]*$/, {
      message: 'Please enter a valid signature',
    }),
})
