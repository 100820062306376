import React from 'react'

import { css, styled } from '~styles'
import { renderAssetIcon } from './aggregate-icon'

interface Props {
  assetCategory?: string
}

const Root = styled.span<{ assetCategory: string }>`
  align-items: center;
  background-color: #6e7d99;
  border-radius: 50%;
  color: ${({ theme }) => theme.white};
  display: inline-flex;
  justify-content: center;
  height: 12px;
  width: 12px;
`

const iconProps = {
  css: css`
    transform: scale(0.75);
  `,
}

const AssetIcon: React.FC<Props> = ({ assetCategory = 'crypto', ...props }) => (
  <Root assetCategory={assetCategory} {...props}>
    {renderAssetIcon(assetCategory, iconProps)}
  </Root>
)

export default AssetIcon
