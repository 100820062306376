/* eslint-disable @typescript-eslint/no-unsafe-return */
import { zodResolver } from '@hookform/resolvers/zod'
import { rem } from 'polished'
import type { FC } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useUIDSeed } from 'react-uid'
import { z } from 'zod'
import { FaChevronLeft } from 'react-icons/fa'

import {
  callToAction,
  footer,
  row,
} from '~/components/application-wizard/mixins'
import Button from '~components/button'
import {
  Field,
  FieldCheckbox,
  FieldHelp,
  FieldInput,
  FieldLabel,
} from '~components/fields'
import { styled } from '~styles'
import { fieldHelp } from '~styles/mixins'
import { noop } from '~utils/functional'
import { formSchema } from './schema'

export type FormValues = z.infer<typeof formSchema>

export interface ReviewFormProps {
  defaultValues?: Partial<FormValues>
  onSubmit?: SubmitHandler<FormValues>
  isLoading?: boolean
  onBack?: () => void
  theme?: string
}

const Form = styled.form`
  min-width: 600px;
`

const Footer = styled.div`
  ${footer}
`

const Help = styled.div`
  ${({ theme }) => fieldHelp(theme.danger)}
`

const CheckboxLabel = styled.div`
  font-size: ${rem(12)};
  font-weight: 500;
  max-width: 575px;
`

const SignatureInput = styled(FieldInput)`
  margin-top: 5px;

  > input {
    font-size: ${rem(16)};
    height: 60px;
    padding-top: 4px;
  }
`

const CallToAction = styled(Button)`
  ${callToAction}
`
const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const GoToPrevious = styled.p`
  gap: 4px;
  font-size: 16px;
  color: #999999;
  align-items: center;
  display: flex;
  flex: 1;
  cursor: pointer;
`

const getDefaultValues = ({
  custodialTerms,
  smsOptIn,
  privacyPolicy,
  ...values
}: Partial<FormValues> = {}) =>
  ({
    custodialTerms:
      typeof custodialTerms === 'boolean' ? String(custodialTerms) : undefined,
    privacyPolicy:
      typeof privacyPolicy === 'boolean' ? String(privacyPolicy) : undefined,
    smsOptIn: typeof smsOptIn === 'boolean' ? String(smsOptIn) : undefined,
    ...values,
  } as Partial<FormValues>)

const ReviewForm: FC<ReviewFormProps> = ({
  defaultValues,
  onSubmit,
  isLoading = false,
  onBack = noop,
  theme,
  ...props
}) => {
  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: zodResolver(formSchema),
  })
  const {
    formState: { errors },
    handleSubmit,
  } = methods

  const seed = useUIDSeed()

  const isFormValid = Object.keys(errors).length === 0

  const custodialPlatformAgreementsLinkProps = {
    href: 'https://digitaltrust.com/self-directed-account-forms',
    target: '_blank',
  }

  const privacyPolicyLinkProps = {
    href: 'https://digitaltrust.com/privacy-policy',
    target: '_blank',
  }

  const termsOfUseLinkProps = {
    href: 'https://digitaltrust.com/terms-conditions',
    target: '_blank',
  }

  const disclosureStatementLinkProps = {
    href: 'https://digitaltrust.com/self-directed-account-forms',
    target: '_blank',
  }

  const feeScheduleLinkProps = {
    href: 'https://digitaltrust.com/self-directed-account-forms',
    target: '_blank',
  }

  return (
    <FormProvider {...methods}>
      <Form
        noValidate
        onSubmit={handleSubmit(data => onSubmit?.(data))}
        {...props}
      >
        <div css={row}>
          <Field name="privacyPolicy">
            <FieldCheckbox id={seed('privacyPolicy')} value="true">
              {theme === 'msdira' ? (
                <CheckboxLabel>
                  By completing this step, I understand that I am opening up a
                  retirement account custodied by Digital Trust. I have
                  received, read, understand, and agree to the terms of the
                  Account Application, Custodial Agreement, Disclosure
                  Statement, Digital Trust and MySelfDirectedIRA Fee Schedule,
                  Privacy Policy, and Terms & Conditions, as updated from time
                  to time and agree to be bound by them.
                </CheckboxLabel>
              ) : (
                <CheckboxLabel>
                  I have received, read, understand, and agree to the terms of
                  the Account Application,
                  <a {...custodialPlatformAgreementsLinkProps}>
                    {` Custodial Agreement`}
                  </a>
                  ,{' '}
                  <a {...disclosureStatementLinkProps}>Disclosure Statement </a>
                  ,<a {...feeScheduleLinkProps}>Fee Schedule </a>,
                  <a {...privacyPolicyLinkProps}> Privacy Policy</a>, and
                  <a {...termsOfUseLinkProps}> Terms & Conditions</a>, as
                  updated from time to time and agree to be bound by them.
                </CheckboxLabel>
              )}
              <FieldHelp />
            </FieldCheckbox>
          </Field>
        </div>
        <div css={row}>
          <Field name="smsOptIn">
            <FieldCheckbox id={seed('smsOptIn')} value="true">
              {theme === 'msdira' ? (
                <CheckboxLabel>
                  I agree to accept email, phone, and/or text message/SMS
                  communications from MySelfDirectedIRA and Digital Trust.
                  Message and Data Rates may apply.
                </CheckboxLabel>
              ) : (
                <CheckboxLabel>
                  I agree to accept email, phone, and/or text message/SMS
                  communications from Digital Trust. Message and data rates may
                  apply.
                </CheckboxLabel>
              )}
              <FieldHelp />
            </FieldCheckbox>
          </Field>
        </div>
        <div css={row}>
          <Field name="custodialTerms">
            <FieldCheckbox id={seed('custodialTerms')} value="true">
              {theme === 'msdira' ? (
                <CheckboxLabel>
                  By checking this box, I would like to receive Marketing
                  Messages from MySelfDirectedIRA and Digital Trust or third
                  party providers that may include breaking marketing news,
                  account alerts, product & features announcements, and
                  operational messages.
                </CheckboxLabel>
              ) : (
                <CheckboxLabel>
                  By checking this box, I would like to receive Marketing
                  Messages from Digital Trust or third party providers that may
                  include breaking market news, account alerts, product &
                  feature announcements, and operational messages.
                </CheckboxLabel>
              )}
              <FieldHelp />
            </FieldCheckbox>
          </Field>
        </div>
        <div css={row}>
          <Field name="importantInformation">
            <FieldCheckbox id={seed('importantInformation')} value="true">
              {theme === 'msdira' ? (
                <CheckboxLabel>
                  CONSENT TO ELECTRONIC SIGNATURE – I agree that by checking
                  this box and signing below, I am stating my consent to sign
                  the Digital Trust Account Application. I certify that all
                  information provided is true and accurate.
                </CheckboxLabel>
              ) : (
                <CheckboxLabel>
                  CONSENT TO ELECTRONIC SIGNATURE - I agree that by checking
                  this box and signing below, I am stating my intent to sign the
                  Account Application. I certify that all information provided
                  in this Application is true and accurate.
                </CheckboxLabel>
              )}
              <FieldHelp />
            </FieldCheckbox>
          </Field>
        </div>
        <div css={row}>
          <Field name="eSignature">
            <FieldLabel>Signature</FieldLabel>
            <SignatureInput placeholder="Type your full name to sign" />
            <FieldHelp />
          </Field>
        </div>
        <Footer>
          {!isFormValid && (
            <Help css={{ marginBottom: 10 }}>
              Please correct any errors before continuing.
            </Help>
          )}
          <ButtonsContainer>
            <GoToPrevious onClick={onBack}>
              <FaChevronLeft /> Previous step
            </GoToPrevious>
            <CallToAction
              isDisabled={!isFormValid || isLoading}
              variant="primary"
            >
              Save & Continue
            </CallToAction>
            <div css={{ flex: 1 }} />
          </ButtonsContainer>
        </Footer>
      </Form>
    </FormProvider>
  )
}

export { ReviewForm }
