import { DialogContent, DialogOverlay, DialogOverlayProps } from '@reach/dialog'
import { rem } from 'polished'
import { useEffect } from 'react'

import { keyframes, mq, styled } from '~styles'
import { EnterCode } from './steps/enter-code'
import { ConfirmPhone } from './steps/confirm-phone'
import { usePhoneVerification } from './use-phone-verification'
import { loadConfig } from '~config.client'

export type PhoneVerificationDialogProps = Pick<
  DialogOverlayProps,
  'isOpen'
> & { phone: string }

export const overlayShow = keyframes`
	0% {
		opacity: 0;
	}
  100% {
		opacity: 1;
	}
`

export const contentShow = keyframes`
	0% {
		opacity: 0;
    transform: translate(-50%, -48%) scale(.96);
	}
  100% {
		opacity: 1;
    transform: translate(-50%, -50%) scale(1);
	}
`

const Overlay = styled(DialogOverlay)`
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  background-color: #00000043;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
`

const Content = styled(DialogContent)`
  animation: ${contentShow} 450ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  background-color: ${({ theme }) => theme.white};
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 0 20px 30px;
  position: fixed;
  margin: unset;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  ${mq('md')} {
    border-radius: 6px;
    max-width: ${rem(600)};
  }
`

const Footer = styled.div`
  color: ${({ theme }) => theme.gray};
  font-size: ${rem(12)};
  text-align: center;
  margin-top: 40px;
`

export function PhoneVerificationDialog({
  phone,
  isOpen,
}: PhoneVerificationDialogProps) {
  const { state, send } = usePhoneVerification()

  useEffect(() => {
    if (state.matches('done')) return

    send({ type: 'SET_PHONE', phone })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, send])

  const { contactPhone } = loadConfig()

  return (
    <Overlay isOpen={isOpen || !state.matches('done')}>
      <Content aria-labelledby="phone-verification-dialog-title">
        {state.matches('confirmPhone') && (
          <ConfirmPhone
            isSubmitting={state.matches('confirmPhone.submitting')}
            onSubmit={phone => send({ type: 'SUBMIT_PHONE', phone })}
            phone={state.context.phone}
          />
        )}
        {state.matches('enterCode') && (
          <EnterCode
            isResending={state.matches('enterCode.resending')}
            isSubmitting={state.matches('enterCode.submitting')}
            onCancel={() => send({ type: 'BACK' })}
            onSubmit={code => send({ type: 'SUBMIT_CODE', code })}
            onResendClick={() => send({ type: 'RESEND_PHONE' })}
            phone={state.context.phone}
          />
        )}
        <Footer>
          For assistance, please call{' '}
          <a href={`tel:${contactPhone}`}>{contactPhone}</a>
        </Footer>
      </Content>
    </Overlay>
  )
}
