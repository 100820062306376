import React, { forwardRef } from 'react'

import { PresetInput, PresetInputProps } from './input'
import { PresetList } from './list'

export type PresetsProps = PresetInputProps

const Presets = forwardRef<HTMLDivElement, PresetsProps>(function Presets(
  { children, ...props },
  ref,
) {
  return (
    <PresetInput ref={ref} {...props}>
      <PresetList>{children}</PresetList>
    </PresetInput>
  )
})
Presets.displayName = 'Presets'

export * from './input'
export * from './list'
export * from './option'
export default Presets
