import { CSSObject } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ComboboxInput as BaseComboboxInput,
  ComboboxInputProps as BaseComboboxInputProps,
} from '@reach/combobox'
import { em, transparentize } from 'polished'
import { ChangeEventHandler, FC, LegacyRef } from 'react'

import { CustomTheme } from '~styles'
import { control } from '~styles/mixins'

type ComboboxInputColor = 'default' | 'danger'

type ComboboxInputVariant = keyof typeof inputVariants

export interface ComboboxInputProps extends BaseComboboxInputProps {
  color?: ComboboxInputColor
  disabled?: boolean
  id?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  ref?: LegacyRef<HTMLInputElement>
  variant?: ComboboxInputVariant
}

const inputColors = (
  theme: CustomTheme,
): Record<ComboboxInputColor, CSSObject> => ({
  default: {
    borderColor: theme.grayLighter,
  },
  danger: {
    borderColor: theme.danger,
  },
})

const inputVariants = {
  default: {
    display: 'inline-block',
  },
  block: {
    display: 'block',
    width: '100%',
  },
}

const ComboboxInput = styled(BaseComboboxInput as FC<ComboboxInputProps>)`
  ${control}
  padding: 0 ${em(15)};
  ${({ color = 'default', theme }) => inputColors(theme)[color]};
  ${({ variant = 'default' }) => inputVariants[variant]};

  &::placeholder {
    color: ${({ theme }) => transparentize(0.7, theme.grayDarker)};
  }
`

export { ComboboxInput }
