import { darken, em, transparentize } from 'polished'
import React from 'react'

import {
  NavMenu,
  NavMenuFooter as BaseNavMenuFooter,
} from '~components/nav-menu'
import BaseToggler from '~components/toggler'
import { loadConfig } from '~config.client'
import { useMedia } from '~hooks/use-media'
import { css, styled } from '~styles'

interface RootProps {
  isExpanded?: boolean
}

const rootWidth = ({ isExpanded }: RootProps) =>
  isExpanded
    ? css`
        width: 255px;
      `
    : css`
        width: auto;
      `

const Root = styled.div<RootProps>`
  background-color: ${({ theme }) => theme.primary};
  display: inline-flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 0 0;
  ${rootWidth}
`

const Header = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

const Title = styled.span`
  color: ${({ theme }) => theme.primaryContrast};
  flex: 1;
  font-size: ${em(11)};
  font-weight: 700;
  letter-spacing: 1px;
`

const Toggler = styled(BaseToggler)`
  color: ${({ theme }) => theme.white};
`

const Divider = styled.div`
  background-color: ${({ theme }) => transparentize(0.5, theme.white)};
  height: 1px;
  margin: ${em(15)} 0;
`

const NavMenuFooter = styled(BaseNavMenuFooter)`
  background-color: rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.primaryContrast};
  padding: 20px;
`

const VerticalNav: React.FC = props => {
  const isDesktop = useMedia(['(min-width: 1439px)'], [true], false)
  const [isExpanded, setExpanded] = React.useState(isDesktop)

  const { contactEmail, contactPhone } = loadConfig()

  return (
    <Root isExpanded={isExpanded} {...props}>
      <div css={{ flex: 1, padding: isExpanded ? '20px' : 0 }}>
        <Header>
          {isExpanded && <Title>MENU</Title>}
          <Toggler
            isActive={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            size={24}
            width={2}
          />
        </Header>
        <Divider />
        <NavMenu
          css={{ fontSize: em(12) }}
          isExpanded={isExpanded}
          variant="dark"
        />
      </div>
      {isExpanded && (
        <NavMenuFooter email={contactEmail} phone={contactPhone} />
      )}
    </Root>
  )
}

export default VerticalNav
