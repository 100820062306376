export const custodians = [
  'American Funds',
  'Ameriprise',
  'Amundi',
  'Apex',
  'AXA',
  'Bank of Utah',
  'Betterment',
  'Brighthouse',
  'CitiBank',
  'Capital One',
  'Charles Schwab',
  'Cor Clearing',
  'DA Davidson',
  'Edward Jones',
  'Entrust',
  'Equity',
  'Etrade',
  'Fidelity | NFS',
  'First Clearing ',
  'First Midwest Bank',
  'Franklin Templeton ',
  'Goldstar Trust company ',
  'Hartford',
  'HD Vest',
  'Horizon Trust Company',
  'Interactive Brokers',
  'IRA Services',
  'JP Morgan Chase',
  'JP Morgan Funds ',
  'Liberty',
  'LPL Financial',
  'Madison Trust Company',
  'Mainstar Trust Company',
  'Mass Mutual',
  'Merrill Edge',
  'Merrill Lynch',
  'Mid Atlantic',
  'Millennium',
  'Nevada Trust Company',
  'Next Generation Trust Company',
  'NuView Trust Company',
  'Pacific Life ',
  'Pensco',
  'Pershing',
  'Preferred Trust',
  'Premier Trust',
  'Primerica',
  'Provident Trust Group',
  'Prudential',
  'Putnam',
  'Oppenheimer',
  'Raymond James',
  'Scottrade',
  'SDIRA/Strata',
  'State Farm Bank',
  'Strata Trust Company ',
  'TD Ameritrade',
  'Transamerica',
  'Tradestation',
  'Trowe Price',
  'US Bank ',
  'UBS',
  'USAA',
  'Valic',
  'Vanguard',
  'Voya',
  'Waddel & Reed',
  'Wealthfront',
  'Wells Fargo',
]
