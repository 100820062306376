import { FC } from 'react'

import { step, stepTitle } from '~components/application-wizard/mixins'
import { styled } from '~styles'
import { ActiveStepComponent } from '../types'
import { UploadForm } from './upload-form'

type VerifyStepProps = ActiveStepComponent

const Root = styled.div`
  ${step}
`

const Title = styled.h2`
  ${stepTitle}
  margin-bottom: 0
`
const Description = styled.p`
  ${stepTitle}
  font-size: 12px;
  color: ${({ theme }) => theme.primary};
  margin-top: 0;
`

const VerifyStep: FC<VerifyStepProps> = ({ onNext, onBack }) => {
  return (
    <Root>
      <Title>Verify Your Identity</Title>
      <Description>
        To comply with Federal Law*, we need to confirm your identity.
      </Description>
      <UploadForm onSubmit={onNext} onBack={onBack} />
    </Root>
  )
}

export { VerifyStep }
