import type { FC } from 'react'

import { styled } from '~styles'

const Root = styled.header`
  padding: 25px 40px;
  max-width: 820px;
  margin: 0 auto;
`

const Title = styled.h1`
  font-size: 28px;
  font-weight: 500;
  text-align: center;
`

const Header: FC = ({ children }) => {
  return (
    <Root>
      <Title>{children}</Title>
    </Root>
  )
}

export { Header }
