import { em } from 'polished'

import { WithCustomTheme, css, mq } from '~styles'

export const card = ({ theme }: WithCustomTheme) => css`
  background: ${theme.backgroundColor};
  border: 1px solid ${theme.grayLighter};
  border-radius: ${theme.borderRadius};
  padding: 10px;
  margin-bottom: 10px;

  ${mq('md')} {
    padding: 10px 20px;
  }
`

export const grid = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${mq('md')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const cell = css`
  margin-bottom: 10px;
`

export const label = ({ theme }: WithCustomTheme) => css`
  color: ${theme.textLightColor};
  font-size: ${em(11)};
  font-weight: 600;
  margin-bottom: 3px;
  text-transform: uppercase;
`

export const value = css`
  font-size: ${em(12)};

  ${mq('md')} {
    font-size: ${em(13)};
  }
`
