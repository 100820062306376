import { em, rem } from 'polished'

import { css } from '~styles'

export const row = css`
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
`

export const callToAction = css`
  align-items: center;
  display: inline-flex;
  font-size: ${em(16)};
  font-weight: 600;
  height: 56px;
  padding: 3px 40px 0;

  &::after {
    content: '›';
    display: inline-block;
    font-size: ${rem(40)};
    font-weight: 400;
    line-height: 1;
    margin: -2px 0 0 10px;
  }
`

export const footer = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
`

export const step = css`
  border: 1px solid #e3e6ee;
  border-radius: 12px;
  margin: 0 auto;
  padding: 25px 30px 50px;
`

export const stepBanner = css`
  background-color: #ecfff6;
  border: 1px solid #7cebae;
  border-radius: 3px;
  font-size: ${em(12)};
  font-weight: 600;
  padding: 8px 20px;
  text-align: center;
`

export const stepTitle = css`
  font-size: ${em(24)};
  font-weight: 600;
  margin: 30px 0;
  text-align: center;
`
