import { em } from 'polished'

import { WithCustomTheme, css } from '~styles'

export const placeholder = ({ theme }: WithCustomTheme) => css`
  color: ${theme.textLightColor};
  font-size: ${em(11)};
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const underlineButton = ({ theme }: WithCustomTheme) => css`
  align-items: center;
  background: transparent;
  border: solid 1px ${theme.grayLighter};
  border-width: 0 0 1px 0;
  cursor: pointer;
  display: inline-flex;
  height: 45px;
  padding: 0;

  &:hover {
    border-color: ${theme.grayLight};
  }
`
