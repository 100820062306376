import { em } from 'polished'
import React from 'react'

import { useLeadOrdersQuery } from '~api/lead-orders'
import { usePortfolioQuery } from '~api/portfolio'
import Loading from '~components/loading'
import TransactionsTable, { toTableData } from '~components/transactions-table'
import { useTrackPageView } from '~hooks/use-track-page-view'
import { mq, styled } from '~styles'
import { card, container, heading } from '~styles/mixins'

const Root = styled.main`
  ${container}
  margin-top: ${({ theme }) => theme.gap}px;

  ${mq('md')} {
    margin-top: 46px;
  }
`

const Title = styled.h3`
  ${heading(700)}
  font-size: ${em(18)};
  margin-bottom: 25px;
`

const Card = styled.div`
  ${card}
  padding: 10px;

  ${mq('md')} {
    padding: 25px;
  }
`

const ErrorText = styled.div`
  color: ${({ theme }) => theme.danger};
`
ErrorText.defaultProps = { role: 'alert' }

const StatementsScreen: React.FC = () => {
  useTrackPageView('Statements')

  const { data: portfolioData, ...portfolioQuery } = usePortfolioQuery()
  const { data, ...leadOrdersQuery } = useLeadOrdersQuery()

  const accountDetailsData = React.useMemo(
    () => portfolioData?.accountDetails ?? [],
    [portfolioData],
  )

  const tableData = React.useMemo(() => (data ? toTableData(data) : []), [data])

  const isLoading = portfolioQuery.isLoading || leadOrdersQuery.isLoading
  const isError = portfolioQuery.isError || leadOrdersQuery.isError
  const error = portfolioQuery.error ?? leadOrdersQuery.error

  return (
    <Root>
      <Title>Transaction History</Title>
      <Card>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <ErrorText>{(error as Error).message}</ErrorText>
        ) : (
          <TransactionsTable
            accountDetailsData={accountDetailsData}
            data={tableData}
          />
        )}
      </Card>
    </Root>
  )
}

export default StatementsScreen
