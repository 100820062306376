import { useMutation, UseMutationOptions } from 'react-query'

import { useSnackbars } from '~context/snackbar-context'
import { client } from '~utils/api-client'
import { getFileName } from '~utils/get-file-name'

interface Document {
  file: File
  fileType: number
}

type DocumentOptions = UseMutationOptions<Document, Error, Document>

const uploadDocument = async ({
  file,
  fileType,
}: Document): Promise<Document> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('name', getFileName(file))
  formData.append('contentType', file.type)
  formData.append('type', String(fileType))

  return await client(`v1/application/documents/upload`, {
    data: formData,
  })
}

export const useUploadDocumentMutation = ({
  onSuccess,
  ...options
}: DocumentOptions = {}) => {
  const { addAlert } = useSnackbars()

  return useMutation(uploadDocument, {
    ...options,
    onSuccess,
    onError: (error: Error) => {
      addAlert(error, { title: 'Failure', variant: 'danger' })
    },
  })
}
