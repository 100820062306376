import React, { forwardRef } from 'react'

import { TableProvider, TableProviderProps } from './context'
import { TableData, TableDataProps } from './data'

export interface TableProps
  extends Omit<TableProviderProps, 'children'>,
    TableDataProps {
  children?: React.ReactNode
}

const Table = forwardRef<HTMLDivElement, TableProps>(function Table(
  { children, isStriped, noData, ...props },
  ref,
) {
  return (
    <TableProvider ref={ref} {...props}>
      {children ?? <TableData noData={noData} isStriped={isStriped} />}
    </TableProvider>
  )
})
Table.displayName = 'Table'

export * from './column-filter-select'
export * from './context'
export * from './data'
export default Table
