import type { FC, ReactNode } from 'react'

import { styled } from '~styles'
import { Footer } from './footer'
import { Header } from './header'

interface LayoutProps {
  title: ReactNode
  disclosure: ReactNode
}

const Main = styled.main`
  margin: 0 auto;
  padding: 40px 0;
  max-width: 715px;
  background-color: #fbfcff;
`

const ApplicationLayout: FC<LayoutProps> = props => {
  const { children, title, disclosure } = props
  return (
    <>
      <Header>{title}</Header>
      <Main>{children}</Main>
      {disclosure && <Footer disclosure={disclosure} />}
    </>
  )
}

export { ApplicationLayout }
