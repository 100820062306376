import { useMutation, useQueryClient } from 'react-query'

import { useSnackbars } from '~context/snackbar-context'
import { client } from '~utils/api-client'

export interface AccountHolderRequest {
  address: string
  city: string
  dateOfBirth: string
  ein: string
  firstName: string
  lastName: string
  middleName?: string
  state: string
  zipCode: string
  marriedStatus: string
}

const submitAccountHolder = async (data: AccountHolderRequest): Promise<void> =>
  client('v1/application/account-holder/submit', { data })

export const useSubmitAccountHolderMutation = ({
  onSuccess,
}: {
  onSuccess: () => void
}) => {
  const queryClient = useQueryClient()
  const { addAlert } = useSnackbars()

  return useMutation(submitAccountHolder, {
    onSuccess: () => {
      onSuccess()
      return queryClient.invalidateQueries('lead')
    },
    onError: (error: Error) => {
      addAlert(error, { title: 'Failure', variant: 'danger' })
    },
  })
}
