import { useMachine } from '@xstate/react'

import { usePatchLeadMutation } from '~api/leads'
import {
  useSendPhoneVerificationMutation,
  useSubmitVerificationCodeMutation,
} from '~api/phone-verifications'
import { useSnackbars } from '~context/snackbar-context'
import { queryClient } from '~utils/query-client'
import { phoneVerificationMachine } from './machine'

export function usePhoneVerification() {
  const { addAlert } = useSnackbars()

  const onError = (error: any) => {
    addAlert(error, { title: 'Failure', variant: 'danger' })
  }

  const { mutateAsync: sendPhoneVerification } =
    useSendPhoneVerificationMutation()

  const { mutateAsync: submitVerificationCode } =
    useSubmitVerificationCodeMutation()

  const { mutate: patchLead } = usePatchLeadMutation()

  const [state, send] = useMachine(phoneVerificationMachine, {
    services: {
      submitCode: (ctx, { code }) =>
        submitVerificationCode(
          { requestId: ctx.requestId, code },
          { onError, onSuccess: () => queryClient.invalidateQueries('lead') },
        ),
      submitPhone: ctx => sendPhoneVerification(ctx.phone, { onError }),
    },
    actions: {
      notifyPhoneVerified: ctx => patchLead(ctx.phone, { onError }),
    },
  })

  return {
    state,
    send,
  } as const
}
