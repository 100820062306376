import { useMutation } from 'react-query'

import { SubscriberName } from '~constants'
import { useAuth } from '~context/auth-context'
import { client } from '~utils/api-client'

const sendPhoneVerification =
  ({ leadId, subscriber }: { subscriber: SubscriberName; leadId: number }) =>
  (phone: string) =>
    client<number>(
      `subscribers/${subscriber}/leads/${leadId}/phone-verifications`,
      { data: { phone } },
    )

export const useSendPhoneVerificationMutation = () => {
  const { subscriber, claims } = useAuth()

  if (!claims || !subscriber) {
    throw new Error('Not authenticated')
  }

  return useMutation(
    sendPhoneVerification({ subscriber, leadId: Number(claims.LeadId) }),
  )
}
