/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { zodResolver } from '@hookform/resolvers/zod'
import { FC } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useUIDSeed } from 'react-uid'
import { z } from 'zod'

import InfoIcon from '~assets/icons/info.svg'
import {
  callToAction,
  footer,
  row,
} from '~/components/application-wizard/mixins'
import Button from '~components/button'
import {
  Field,
  FieldHelp,
  FieldInput,
  FieldLabel,
  FieldRadio,
} from '~components/fields'
import { DepositRecurrence, Product } from '~models/common'
import { styled } from '~styles'
import { fieldHelp, fieldHint, controlLabel } from '~styles/mixins'
import { currencyMask } from '~utils/masker'
import { saverFormSchema } from './schema'
import { pickNonNullValues } from '~utils/object'

export type FormValues = z.infer<typeof saverFormSchema>

export interface SaverAccountFormProps {
  defaultValues?: Partial<FormValues>
  onSubmit?: SubmitHandler<FormValues>
  isLoading?: boolean
}

const Label = styled.div`
  ${controlLabel}
`

const Help = styled.div`
  ${({ theme }) => fieldHelp(theme.danger)}
`

const Hint = styled.div`
  ${({ theme }) => fieldHint(theme.textLightColor)}
  align-items: center;
  display: flex;
  margin-top: 4px;
  text-align: right;
`

const Footer = styled.div`
  ${footer}
`

const RadioGroup = styled.div`
  display: flex;

  > div:not(:last-of-type) {
    margin-right: 20px;
  }
`

const CallToAction = styled(Button)`
  ${callToAction}
`

const getDefaultValues = ({
  depositRecurrence,
  investAmount,
  ...values
}: Partial<FormValues> = {}) =>
  pickNonNullValues({
    product: Product.Saver,
    depositRecurrence: depositRecurrence
      ? String(depositRecurrence)
      : undefined,
    investAmount: investAmount
      ? currencyMask.maskDefault(investAmount)
      : undefined,
    ...values,
  })

const SaverAccountForm: FC<SaverAccountFormProps> = ({
  defaultValues,
  onSubmit,
  isLoading = false,
  ...props
}) => {
  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: zodResolver(saverFormSchema),
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods

  const seed = useUIDSeed()

  const isFormValid = Object.keys(errors).length === 0

  return (
    <FormProvider {...methods}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(data => onSubmit?.(data))}
        {...props}
      >
        <input {...register('product')} type="hidden" value={Product.Saver} />
        <div css={[row, { display: 'flex' }]}>
          <Field css={{ flex: 1, marginRight: 20 }} name="investAmount">
            <FieldLabel>Total Amount To Transfer</FieldLabel>
            <FieldInput
              onChange={currencyMask.onChange}
              placeholder="Enter Amount"
            />
            <FieldHelp />
            <Hint>
              <InfoIcon css={{ marginRight: 5 }} />
              You can change this later
            </Hint>
          </Field>
          <div css={{ flex: 1 }} />
        </div>

        <Field
          aria-labelledby={seed('depositRecurrence-label')}
          css={row}
          name="depositRecurrence"
          role="radiogroup"
        >
          <Label id={seed('depositRecurrence-label')}>Occurance</Label>
          <RadioGroup>
            <FieldRadio
              id={seed('depositRecurrence-1')}
              value={DepositRecurrence.OneTime}
            >
              One-Time
            </FieldRadio>
            <FieldRadio
              id={seed('depositRecurrence-2')}
              value={DepositRecurrence.Monthly}
            >
              Monthly
            </FieldRadio>
          </RadioGroup>
          <FieldHelp />
        </Field>
        <Footer>
          {!isFormValid && (
            <Help css={{ marginBottom: 10 }}>
              Please correct any errors before continuing.
            </Help>
          )}
          <CallToAction
            isDisabled={!isFormValid || isLoading}
            variant="primary"
          >
            Save & Continue
          </CallToAction>
        </Footer>
      </form>
    </FormProvider>
  )
}

export { SaverAccountForm }
