import { useMemo } from 'react'
import { Row } from 'react-table'

import { useTableContext } from '~components/table'

export const useColumnValues = (columnId: string) => {
  const { preFilteredRows = [] } = useTableContext()

  return useMemo(() => {
    const set = preFilteredRows.reduce((acc: Set<string>, row: Row<any>) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const value = row.values[columnId]

      return value ? acc.add(value) : acc
    }, new Set<string>())

    return [...set.values()]
  }, [columnId, preFilteredRows])
}
