import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useTrackPageView } from '~hooks/use-track-page-view'
import BaseSuiteBoy from '~assets/images/suit-boy.png'

export default function ApplicationCompleteScreen() {
  useTrackPageView('ApplicationCompleteScreen')
  return (
    <main className="mx-auto max-w-[715px] bg-[#fbfcff] py-10">
      <div className="mx-auto mt-10  bg-[#fbfcff] text-center">
        <div className="mb-[30px] flex flex-col items-center rounded-[10px] bg-white pb-[60px] shadow-[0_1px_6px_0_rgba(0,0,0,0.14)]">
          <img alt="Congratulations!" src={BaseSuiteBoy} />
          <h2 className="text-primary text-[26px] font-semibold">
            Congratulations
          </h2>
          <h5 className="text-primary mb-[10px] text-base font-medium">
            Your information has been successfully submitted!
          </h5>
          <p className="mb-[50px] text-sm text-[#666666]">
            Once approved by our team, we will contact you to complete your
            account set-up.
          </p>
          <RouterLink
            className="bg-primary rounded-[5px] px-[50px] py-[14px] text-sm text-white"
            to="/"
          >
            Return To Dashboard
          </RouterLink>
        </div>
      </div>
    </main>
  )
}
