import { rem } from 'polished'
import React from 'react'
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderProps,
  Meta,
} from 'react-table'

import { css, styled } from '~styles'

export interface ExpanderProps {
  isActive?: boolean
}

const Expander = styled.span<ExpanderProps>`
  color: ${({ theme }) => theme.grayDark};
  font-size: ${rem(8)};
  line-height: 1;
  padding: 5px;
  transition: transform 100ms ease;
  transform: rotate(-90deg);

  &::after {
    content: '▲';
  }

  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(-180deg);
    `}
`

export const useExpanderColumn = <T extends object = any>(
  columns: ColumnInstance<T>[],
  _meta: Meta<T>,
) => {
  const expanderColumn: Column<T> = {
    id: 'expander',
    align: 'right',
    minWidth: 20,
    width: 20,
    Header: ({
      getToggleAllRowsExpandedProps,
      isAllRowsExpanded,
    }: HeaderProps<T>) => (
      <Expander
        isActive={isAllRowsExpanded}
        role="button"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        {...getToggleAllRowsExpandedProps()}
      />
    ),
    Cell: ({ row }: CellProps<T>) => (
      <Expander
        isActive={row.isExpanded}
        role="button"
        {...row.getToggleRowExpandedProps()}
      />
    ),
  }

  return [...columns, expanderColumn]
}
