import React, { FC, ReactElement } from 'react'

import { styled } from '~styles'

interface UploadInformationProps {
  icon: ReactElement
  title: string
  description: string
}

const UploadInformationContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
`
const Title = styled.h4`
  font-weight: 600;
`
const Description = styled.p`
  font-size: 10px;
  color: #666666;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`
const Icon = styled.div`
  background-color: #f1f5ff;
  border-radius: 60px;
  text-align: center;

  [data-theme='msdira'] & {
    background-color: rgba(61, 174, 30, 0.1);
  }
`

export const UploadInformation: FC<UploadInformationProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <UploadInformationContainer>
      <Icon>{icon}</Icon>
      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleContainer>
    </UploadInformationContainer>
  )
}
