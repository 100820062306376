import { useMutation } from 'react-query'

import { SubscriberName } from '~constants'
import { useAuth } from '~context/auth-context'
import { client } from '~utils/api-client'

const patchLead =
  ({ leadId, subscriber }: { subscriber: SubscriberName; leadId: number }) =>
  (phone: string) =>
    client<void>(`subscribers/${subscriber}/leads/${leadId}`, {
      method: 'PATCH',
      data: [{ op: 'add', path: '/phone', value: phone }],
    })

export const usePatchLeadMutation = () => {
  const { subscriber, claims } = useAuth()

  if (!claims || !subscriber) {
    throw new Error('Not authenticated')
  }

  return useMutation(patchLead({ subscriber, leadId: Number(claims.LeadId) }))
}
