import { em } from 'polished'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '~context/auth-context'
import { useTrackPageView } from '~hooks/use-track-page-view'
import { styled } from '~styles'
import { card, heading } from '~styles/mixins'
import PasswordResetForm from '../recover/password-reset-form'

export interface Props {
  email?: string
  isSubmitted?: boolean
}

const Root = styled.div`
  ${card}
  border-top: 4px solid ${({ theme }) => theme.primary};
  padding: 30px ${({ theme }) => theme.gap}px;
  margin: 40px auto 0;
  min-height: 420px;
  max-width: 600px;
`

const CardBody = styled.div`
  margin: auto;
  max-width: 450px;
`

const Title = styled.h2`
  ${heading(700)}
  font-size: ${em(24)};
  margin-bottom: 10px;
  text-align: center;
`

const Sub = styled.div`
  font-size: ${em(16)};
  font-weight: 600;
  margin: 0 auto ${em(40)};
  max-width: 350px;
  text-align: center;
`

const ResetPassword: React.FC<Props> = ({ email }) => {
  useTrackPageView('Confirm recover password')

  const navigate = useNavigate()
  const { claims } = useAuth()

  return (
    <Root>
      <CardBody>
        <Title>Reset Your Password</Title>
        <Sub>Enter your new password here</Sub>
        <PasswordResetForm
          email={email ?? (claims?.Email || '')}
          onSuccess={() => navigate('/')}
        />
      </CardBody>
    </Root>
  )
}

export default ResetPassword
