/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
import { em, rem, transparentize } from 'polished'
import React, { forwardRef } from 'react'
import { Row } from 'react-table'

import { css, styled } from '~styles'
import { unselectable } from '~styles/mixins'
import { isFunction } from '~utils/functional'
import { useTableContext } from './context'
import { AnyComponentType, ComponentType } from './types'
import { cellProps, headerProps, footerProps, renderCell } from './utils'

type TrProps = {
  isDivided?: boolean
  isStriped?: boolean
}

interface OrderingIconProps {
  isActive?: boolean
}

export interface TableSubcomponentProps<T extends object = any> {
  row: Row<T>
}

export interface TableDataProps extends TrProps {
  noData?: React.FC
  renderSubcomponent?: (props: TableSubcomponentProps) => React.ReactNode
  showFooter?: boolean
  tfootAs?: AnyComponentType
  theadAs?: AnyComponentType
  tdAs?: AnyComponentType
  thAs?: AnyComponentType
  trAs?: ComponentType<TrProps>
}

export const TFoot = styled.div`
  border-top: 1px solid ${({ theme }) => theme.grayLightest};
`

export const Th = styled.div`
  ${unselectable}
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(12)};
  overflow: hidden;
  padding: 0 0 8px;
`

export const Tr = styled.div<TrProps>`
  border: 0 solid ${({ theme }) => theme.grayLightest};

  ${({ isDivided }) =>
    isDivided &&
    css`
      &:not(:last-child) {
        border-bottom-width: 1px;
      }
    `}

  ${({ isStriped }) =>
    isStriped &&
    css`
      &:nth-of-type(even) {
        background-color: ${transparentize(0.14, '#f5f8ff')};
      }
    `}
`

export const Td = styled.div`
  font-size: ${em(13)};
  padding: 6px 0;
`

const OrderingIcon = styled.span<OrderingIconProps>`
  color: ${({ theme }) => theme.grayDark};
  font-size: ${rem(8)};
  line-height: 1;
  padding: 5px;
  transition: transform 100ms ease;

  &::after {
    content: '▲';
  }

  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(-180deg);
    `}
`

export const TableData = forwardRef<HTMLDivElement, TableDataProps>(
  function TableData(
    {
      isDivided,
      isStriped,
      noData: NoDataComp,
      showFooter,
      renderSubcomponent,
      tfootAs: TFootComp = TFoot,
      theadAs: THeadComp = 'div',
      tdAs: TdComp = Td,
      thAs: ThComp = Th,
      trAs: TrComp = Tr,
      ...props
    },
    ref,
  ) {
    const { footerGroups, getTableProps, headerGroups, prepareRow, rows } =
      useTableContext()

    if (rows.length === 0 && NoDataComp) {
      return <NoDataComp />
    }

    return (
      <div ref={ref} {...props} {...getTableProps()}>
        <THeadComp>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <ThComp
                  {...column.getHeaderProps(
                    column.getSortByToggleProps?.(headerProps as any) ??
                      headerProps,
                  )}
                >
                  {column.render('Header')}
                  {column.isSorted && (
                    <OrderingIcon isActive={column.isSortedDesc} />
                  )}
                </ThComp>
              ))}
            </div>
          ))}
        </THeadComp>
        <div data-testid="tbody">
          {rows.map(row => {
            prepareRow(row)

            const { key, ...rowProps } = row.getRowProps()

            return isFunction(renderSubcomponent) ? (
              <TrComp key={key} isDivided={isDivided} isStriped={isStriped}>
                <div {...rowProps}>
                  {row.cells.map(cell => (
                    <TdComp {...cell.getCellProps(cellProps)}>
                      {renderCell(cell)}
                    </TdComp>
                  ))}
                </div>
                {row.isExpanded && renderSubcomponent({ row })}
              </TrComp>
            ) : (
              <TrComp
                key={key}
                isDivided={isDivided}
                isStriped={isStriped}
                {...rowProps}
              >
                {row.cells.map(cell => (
                  <TdComp {...cell.getCellProps(cellProps)}>
                    {renderCell(cell)}
                  </TdComp>
                ))}
              </TrComp>
            )
          })}
        </div>
        {showFooter && (
          <TFootComp data-testid="tfoot">
            {footerGroups.map(group => (
              <TrComp {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <TdComp {...column.getFooterProps(footerProps)}>
                    {column.render('Footer')}
                  </TdComp>
                ))}
              </TrComp>
            ))}
          </TFootComp>
        )}
      </div>
    )
  },
)
TableData.displayName = 'TableData'
