import { ListboxOption } from '@reach/listbox'
import { em } from 'polished'

import { styled } from '~styles'

export const FilterOption = styled(ListboxOption)`
  font-size: ${em(14)};
  letter-spacing: 1px;
  padding: 4px 20px;

  &[data-current] {
    font-weight: 600;
  }
`
