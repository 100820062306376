import { useQuery } from 'react-query'

import { SubscriberName } from '~constants'
import { useAuth } from '~context/auth-context'
import { useSnackbars } from '~context/snackbar-context'
import { PortfolioResponse } from '~models/portfolio'
import { client } from '~utils/api-client'

type FetchPortfolioVariables = {
  subscriber: SubscriberName
  leadId: number
}

export const fetchPortfolio = ({
  leadId,
  subscriber,
}: FetchPortfolioVariables) =>
  client<PortfolioResponse>(
    `subscribers/${subscriber}/leads/${leadId}/portfolio`,
  )

export const usePortfolioQuery = () => {
  const { addAlert } = useSnackbars()
  const { subscriber, claims } = useAuth()

  if (!claims || !subscriber) {
    throw new Error('Not authenticated')
  }

  const variables: FetchPortfolioVariables = {
    leadId: Number(claims.LeadId),
    subscriber,
  }

  return useQuery(['portfolio'], () => fetchPortfolio(variables), {
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
    onError: (error: Error | string) => {
      addAlert(error, { title: 'Failure', variant: 'danger' })
    },
  })
}
