import { em, rem } from 'polished'
import React, { forwardRef } from 'react'

import { WithCustomTheme, css, styled } from '~styles'
import { focusRing, unselectable } from '~styles/mixins'
import { usePresetContext } from './input'

export interface PresetOptionProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  value: string
}

interface RootProps {
  isSelected?: boolean
}

const fontSize = 12

const unselected = ({ theme }: WithCustomTheme) => css`
  background-color: ${theme.white};
  border-color: ${theme.grayLighter};
  font-size: ${rem(fontSize)};
  outline: none;

  &:hover {
    border-color: ${theme.grayLight};
  }

  &:focus {
    border-color: ${theme.linkFocusBorderColor};
  }

  &:active {
    border-color: ${theme.linkActiveBorderColor};
  }
`

const selected = ({ theme }: WithCustomTheme) => css`
  background-color: ${theme.tertiary};
  border-color: ${theme.tertiary};
  color: ${theme.tertiaryContrast};
  outline: none;

  &:focus {
    ${focusRing}
  }
`

const Root = styled.span<RootProps>`
  ${unselectable}
  align-items: center;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  cursor: pointer;
  display: inline-flex;
  font-size: ${rem(fontSize)};
  height: ${em(28, fontSize)};
  justify-content: center;
  line-height: 1;
  min-width: 70px;
  padding: 0 ${em(10, fontSize)};
  white-space: nowrap;
  ${({ isSelected }) => (isSelected ? selected : unselected)}
`

export const PresetOption = forwardRef<HTMLSpanElement, PresetOptionProps>(
  function PresetOption({ children, value, ...props }, ref) {
    const [{ onValueChange, value: stateValue }, send] = usePresetContext()

    const handleClick = () =>
      send({ type: 'SET_VALUE', callback: onValueChange, value })

    const isSelected = stateValue === value

    return (
      <Root
        aria-checked={isSelected}
        data-preset-option=""
        isSelected={isSelected}
        onClick={handleClick}
        ref={ref}
        role="radio"
        {...props}
      >
        {children}
      </Root>
    )
  },
)
PresetOption.displayName = 'PresetOption'
