import { em, rem } from 'polished'

import { LinkButton } from '~components/button'
import { styled } from '~styles'
import { heading, spinner } from '~styles/mixins'
import { EnterCodeForm } from './enter-code-form'
import MfaIcon from './mfa-icon.svg'
import MfaIconMSDIRA from './mfa-icon-msdira.svg'
import { loadConfig } from '~config.client'

const Root = styled.div`
  position: relative;
  padding-top: 20px;
  text-align: center;
`
const BackLink = styled(LinkButton)`
  color: ${({ theme }) => theme.gray};
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 0;
`

const ResendLink = styled(LinkButton)`
  font-weight: 500;
`

const Title = styled.h2`
  ${heading(700)}
  font-size: ${rem(24)};
  margin: ${rem(12)} 0;
`

const Description = styled.p`
  font-size: ${rem(14)};
  margin: 0 auto ${rem(20)};
  max-width: ${em(350, 14)};

  > b {
    font-weight: 700;
  }
`

const Spinner = styled.span`
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;

  &::after {
    ${spinner()}
  }
`

export function EnterCode({
  isResending,
  isSubmitting,
  onCancel,
  onSubmit,
  onResendClick,
  phone,
}: {
  isResending?: boolean
  isSubmitting?: boolean
  onCancel: () => void
  onSubmit: (code: string) => void
  onResendClick: () => void
  phone: string
}) {
  return (
    <Root>
      <BackLink onClick={onCancel}>Go Back</BackLink>
      <div className="flex items-center justify-center">
        {loadConfig().siteTheme === 'msdira' ? (
          <MfaIconMSDIRA className="mt-3" />
        ) : (
          <MfaIcon className="mt-3" />
        )}
      </div>
      <Title id="phone-verification-dialog-title">
        Please enter your verification code
      </Title>
      <Description>
        The code was sent to your number ending in <b>{phone.slice(-4)}</b>
      </Description>
      <EnterCodeForm
        isSubmitting={isSubmitting}
        onSubmit={data => onSubmit(data.code)}
      />
      <ResendLink onClick={onResendClick}>
        I didn't receive it, resend code. {isResending && <Spinner />}
      </ResendLink>
    </Root>
  )
}
