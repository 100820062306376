/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { zodResolver } from '@hookform/resolvers/zod'
import React, { FC } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import InfoIcon from '~assets/icons/info.svg'
import {
  callToAction,
  footer,
  row,
} from '~/components/application-wizard/mixins'
import Button from '~components/button'
import { Field, FieldHelp, FieldInput, FieldLabel } from '~components/fields'
import { Product } from '~models/common'
import { styled } from '~styles'
import { fieldHelp, fieldHint } from '~styles/mixins'
import { currencyMask } from '~utils/masker'
import { AccountTypeSelect } from './account-type-select'
import { CustodianInput } from './custodian-input'
import { primeFormSchema } from './schema'
import { pickNonNullValues } from '~utils/object'

export type FormValues = z.infer<typeof primeFormSchema>

export interface PrimeAccountFormProps {
  defaultValues?: Partial<FormValues>
  onSubmit?: SubmitHandler<FormValues>
  isLoading?: boolean
}

const Help = styled.div`
  ${({ theme }) => fieldHelp(theme.danger)}
`

const Hint = styled.div`
  ${({ theme }) => fieldHint(theme.textLightColor)}
  align-items: center;
  display: flex;
  margin-top: 4px;
  text-align: right;
`

const Footer = styled.div`
  ${footer}
`

const CallToAction = styled(Button)`
  ${callToAction}
`

const getDefaultValues = ({
  accountValue,
  investAmount,
  ...values
}: Partial<FormValues> = {}) =>
  pickNonNullValues({
    accountType: 'TraditionalIra',
    product: Product.Prime,
    accountValue: accountValue
      ? currencyMask.maskDefault(accountValue)
      : undefined,
    investAmount: investAmount
      ? currencyMask.maskDefault(investAmount)
      : undefined,
    ...values,
  } as Partial<FormValues>)

const PrimeAccountForm: FC<PrimeAccountFormProps> = ({
  defaultValues,
  onSubmit,
  isLoading = false,
  ...props
}) => {
  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: zodResolver(primeFormSchema),
  })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods

  const isFormValid = Object.keys(errors).length === 0

  return (
    <FormProvider {...methods}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(data => onSubmit?.(data))}
        {...props}
      >
        <input {...register('product')} type="hidden" value={Product.Prime} />
        <Field css={row} name="accountType">
          <FieldLabel>What kind of retirement account do you have?</FieldLabel>
          <AccountTypeSelect />
          <FieldHelp />
        </Field>
        <div css={[row, { display: 'flex' }]}>
          <Field css={{ flex: 1, marginRight: 20 }} name="custodian">
            <FieldLabel>Who is your current account with?</FieldLabel>
            <CustodianInput placeholder="Start typing..." variant="block" />
            <FieldHelp />
          </Field>
          <Field css={{ flex: 1 }} name="releasingCustodianAccountNumber">
            <FieldLabel>Account Number (optional)</FieldLabel>
            <FieldInput />
            <FieldHelp />
          </Field>
        </div>
        <div css={[row, { display: 'flex' }]}>
          <Field css={{ flex: 1, marginRight: 20 }} name="accountValue">
            <FieldLabel>Total Amount In Your IRA</FieldLabel>
            <FieldInput
              onChange={currencyMask.onChange}
              placeholder="Enter Amount"
            />
            <FieldHelp />
          </Field>
          <Field css={{ flex: 1 }} name="investAmount">
            <FieldLabel>Total Amount To Transfer</FieldLabel>
            <FieldInput
              onChange={currencyMask.onChange}
              placeholder="Enter Amount"
            />
            <FieldHelp />
            <Hint>
              <InfoIcon css={{ marginRight: 5 }} />
              You can change this later
            </Hint>
          </Field>
        </div>
        <Footer>
          {!isFormValid && (
            <Help css={{ marginBottom: 10 }}>
              Please correct any errors before continuing.
            </Help>
          )}
          <CallToAction
            isDisabled={!isFormValid || isLoading}
            variant="primary"
          >
            Save & Continue
          </CallToAction>
        </Footer>
      </form>
    </FormProvider>
  )
}

export { PrimeAccountForm }
