import { useRef } from 'react'

import { isNil } from '~utils/functional'

export const useControlledStateSync = <T>(
  controlPropValue: T | undefined,
  internalValue: T,
  callback: () => void,
) => {
  const { current: isControlled } = useRef(!isNil(controlPropValue))

  if (isControlled && controlPropValue !== internalValue) {
    callback()
  }
}
