import type { FC } from 'react'
import { em } from 'polished'

import { styled } from '~styles'

const disclosures = [
  "USA Patriot Act: To cooperate with the US Government's efforts to combat the funding of terrorism and money laundering. Federal Law requires all financial institutions to obtain, verify, and record the identity of each individual who opens an account. Accordingly, when you open an account, we will request your name, address, date of birth, a copy of your driver's license or passport, and other information that will help us identify you.",
]

const Paragraph = styled.p`
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(12)};
  line-height: 1.25;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const VerifyDisclosure: FC = () => {
  return (
    <>
      {disclosures.map((disclosure, idx) => (
        <Paragraph key={idx}>{disclosure}</Paragraph>
      ))}
    </>
  )
}

export { VerifyDisclosure }
