import { format, parseISO } from 'date-fns'
import React from 'react'

import { Transaction } from '~models/trade'
import { mq, styled } from '~styles'
import { formatEmpty } from '~utils/format'
import { card, cell, label, value } from './mixins'

export interface Props {
  data: Transaction
}

const Root = styled.div`
  ${card}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${mq('md')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const Cell = styled.span`
  ${cell}
`

const Label = styled.div`
  ${label}
`

const Value = styled.div`
  ${value}
`

const TransactionDetail: React.FC<Props> = ({ data, ...props }) => {
  const {
    assetName,
    custodianAccountNumber,
    description,
    quantity,
    transactionDateTime,
    transactionTypeName,
  } = data

  const cells = [
    { label: 'Account Number', value: custodianAccountNumber },
    {
      label: 'Transaction Date',
      value: format(parseISO(transactionDateTime), 'yyyy-MM-dd HH:mm:ss'),
    },
    { label: 'Transaction Type', value: transactionTypeName },
    { label: 'Asset', value: formatEmpty(assetName) },
    { label: 'Units', value: quantity },
    { label: 'Note', value: description },
  ]

  return (
    <Root {...props}>
      <Grid>
        {cells.map(({ label, value }) => (
          <Cell key={label}>
            <Label>{label}</Label>
            <Value>{value}</Value>
          </Cell>
        ))}
      </Grid>
    </Root>
  )
}

export default TransactionDetail
