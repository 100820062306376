import { useEffect, useState } from 'react'

export const useMedia = <T>(
  queries: string[],
  values: T[],
  defaultValue: T,
) => {
  const queryList: MediaQueryList[] = queries.map(q => window.matchMedia(q))

  const getValue = () => {
    const idx = queryList.findIndex(query => query.matches)

    return values[idx] ?? defaultValue
  }

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    const handler = () => setValue(getValue)

    queryList.forEach(q => q.addEventListener('change', handler))

    return () =>
      queryList.forEach(q => q.removeEventListener('change', handler))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return value
}
