import type { FC } from 'react'
import { em } from 'polished'

import { styled } from '~styles'

const disclosures = [
  'Digital Trust, LLC ("Digital Trust") is a Trust Company licensed and regulated by the State of Nevada Financial Institutions Division.',
  'Digital Trust is a passive custodian of self-directed accounts whose role is non-discretionary and administrative only. Digital Trust does provide investment, legal, tax, or financial advice.',
  'The account holder must direct all investment transactions and choose the investments for the account. Digital Trust has no responsibility or involvement in selecting any investment.',
  'Clients should consult with a competent CPA, tax advisor, and/or legal counsel for advice.',
]

const Paragraph = styled.p`
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(12)};
  line-height: 1.25;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const ReviewDisclosure: FC = () => {
  return (
    <>
      {disclosures.map((disclosure, idx) => (
        <Paragraph key={idx}>{disclosure}</Paragraph>
      ))}
    </>
  )
}

export { ReviewDisclosure }
