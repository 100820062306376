import {
  Cell,
  CellPropGetter,
  FooterPropGetter,
  HeaderPropGetter,
} from 'react-table'

type Data = any

const getStyles = (props: Record<string, unknown>, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]

export const headerProps: HeaderPropGetter<Data> = (props, { column }) => [
  ...getStyles(props, column.align),
  { title: undefined }, // prevent sortBy from setting title
]

export const footerProps: FooterPropGetter<Data> = (props, { column }) =>
  getStyles(props, column.align)

export const cellProps: CellPropGetter<Data> = (props, { cell }) =>
  getStyles(props, cell.column.align)

export const renderCell = (cell: Cell<Data>) =>
  cell.isGrouped
    ? cell.render('Cell')
    : cell.isAggregated
    ? cell.render('Aggregated')
    : cell.isPlaceholder
    ? cell.render('Placeholder')
    : cell.render('Cell')
