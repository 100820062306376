import { em } from 'polished'
import React, { useState } from 'react'
import { useBlocker, useNavigate } from 'react-router-dom'

import { NavigationState, useAuth } from '~context/auth-context'
import { useSnackbars } from '~context/snackbar-context'
import { useTrackPageView } from '~hooks/use-track-page-view'
import { styled } from '~styles'
import { card, heading } from '~styles/mixins'
import ActivateForm from './activate-form'

const Root = styled.div`
  ${card}
  border-top: 4px solid ${({ theme }) => theme.primary};
  padding: 30px ${({ theme }) => theme.gap}px;
  margin: auto;
  max-width: 600px;
`

const CardBody = styled.div`
  margin: auto;
  max-width: 450px;
`

const Title = styled.h2`
  ${heading(700)}
  font-size: ${em(24)};
  margin-bottom: ${em(5)};
  text-align: center;
`

const Sub = styled.div`
  font-size: ${em(16)};
  font-weight: 600;
  margin: 0 auto ${em(10)};
  max-width: 320px;
  text-align: center;
`

const Footer = styled.div`
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(11)};
  padding: 20px 0;
  text-align: center;
`

const Activate: React.FC = () => {
  useTrackPageView('Activate account')

  const [isBlocking, setIsBlocking] = useState(true)
  const { addAlert } = useSnackbars()
  const navigate = useNavigate()
  const { claims } = useAuth()

  useBlocker(transition => {
    const navigationState = transition.location.state as NavigationState
    const isLogoutAttempt = navigationState?.isLogout

    if (!isLogoutAttempt) {
      addAlert('Please complete account activation.', {
        title: 'Not Authorized',
        variant: 'danger',
        autoDismissTimeout: 10000,
      })
    }
  }, isBlocking)

  const onSuccess = () => {
    setIsBlocking(false)
    addAlert('Your account is now active', { variant: 'success' })
    navigate('/')
  }

  return (
    <Root>
      <CardBody>
        <Title>Activate your all-new portal</Title>
        <Sub>
          Access new features and better reporting with the same great service
          and support.
        </Sub>
        <ActivateForm email={claims?.Email || ''} onSuccess={onSuccess} />
        <Footer>
          By clicking "Activate Account" you agree to Digital Trust Company{' '}
          <a href="https://digitaltrust.com/terms-conditions" target="_blank">
            Terms of Service
          </a>
          ,{' '}
          <a href="https://digitaltrust.com/privacy-policy" target="_blank">
            Privacy Statement
          </a>
          , and{' '}
          <a
            href="https://digitaltrust.com/self-directed-account-forms"
            target="_blank"
          >
            Risk Disclosures
          </a>
        </Footer>
      </CardBody>
    </Root>
  )
}

export default Activate
