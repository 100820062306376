import useThrottle from '@rooks/use-throttle'
import { matchSorter } from 'match-sorter'
import { em } from 'polished'
import { FC, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { useInputField } from '~components/fields/input'
import {
  Combobox,
  ComboboxInput,
  ComboboxInputProps,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '~components/inputs/combobox'
import { custodians } from '~constants/custodian'
import { styled } from '~styles'

export type CustodianInputProps = Omit<ComboboxInputProps, 'onChange'>

const useCustodianMatch = (term: string) =>
  useMemo(
    () => (term.trim() === '' ? undefined : matchSorter(custodians, term)),
    [term],
  )

const Empty = styled.p`
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(13)};
  font-style: italic;
  text-align: center;
  padding: 4px;
`

const CustodianInput: FC<CustodianInputProps> = ({ id, ...props }) => {
  const [term, setTerm] = useState('')
  const [throttledSetTerm] = useThrottle(setTerm, 200)

  const results = useCustodianMatch(term)

  const { isInvalid, inputProps, inputRef } = useInputField({
    id,
    onChange: e => {
      throttledSetTerm(e.target.value)
    },
  })

  const { setValue, watch } = useFormContext()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const watchValue: string = watch(inputProps.name) ?? ''

  const handleSelect = (value: string) => {
    setValue(inputProps.name, value)
    setTerm('')
  }

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        color={isInvalid ? 'danger' : 'default'}
        ref={inputRef}
        value={watchValue}
        {...inputProps}
        {...props}
      />
      {results && (
        <ComboboxPopover>
          {results.length > 0 ? (
            <ComboboxList>
              {results.slice(0, 10).map((val, idx) => (
                <ComboboxOption key={idx} value={val} />
              ))}
            </ComboboxList>
          ) : (
            <Empty>No suggestions</Empty>
          )}
        </ComboboxPopover>
      )}
    </Combobox>
  )
}

export { CustodianInput }
