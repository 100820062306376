import { useMutation, useQueryClient } from 'react-query'

import { useSnackbars } from '~context/snackbar-context'
import { client } from '~utils/api-client'

export interface ReviewRequest {
  custodialTerms?: boolean
  privacyPolicy: boolean
  importantInformation: boolean
  smsOptIn: boolean
  eSignature: string
  eSignatureConsent: boolean
  phone: string
}

const submitReview = async (data: ReviewRequest): Promise<void> =>
  client('v1/application/review/submit', { data })

export const useSubmitReviewMutation = ({
  onSuccess,
}: {
  onSuccess: () => void
}) => {
  const queryClient = useQueryClient()
  const { addAlert } = useSnackbars()

  return useMutation(submitReview, {
    onSuccess: () => {
      onSuccess()
      return queryClient.invalidateQueries('lead')
    },
    onError: (error: Error) => {
      addAlert(error, { title: 'Failure', variant: 'danger' })
    },
  })
}
