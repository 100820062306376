import React from 'react'

import Select from '~components/select'
import { styled } from '~styles'
import { option } from '~styles/mixins'
import { useTableContext } from './context'

export interface TableColumnFilterSelectProps
  extends Omit<React.HTMLAttributes<HTMLSelectElement>, 'onChange'> {
  columnId: string
  placeholder?: string
}

const Option = styled.option`
  ${option}
`

export const TableColumnFilterSelect: React.FC<
  TableColumnFilterSelectProps
> = ({ columnId, placeholder = 'All', ...props }) => {
  const { preFilteredRows = [], setFilter } = useTableContext()

  const options = React.useMemo(() => {
    const set = preFilteredRows.reduce(
      (acc, row) => acc.add(row.values[columnId]),
      new Set<string>(),
    )

    return [...set.values()]
  }, [columnId, preFilteredRows])

  return (
    <Select
      onChange={e => setFilter(columnId, e.currentTarget.value || undefined)}
      {...props}
    >
      <option value="">{placeholder}</option>
      {options.map((option, idx) => (
        <Option key={idx} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  )
}
