import type { FC } from 'react'

import { styled } from '~styles'

interface DevtoolsProps {
  onBack: () => void
  onNext: () => void
  onReset: () => void
}

const Root = styled.div`
  background-color: #f0f0f0;
  margin-top: 40px;
  padding: 10px;
  position: absolute;
  top: 150px;
  left: 20px;
`

const Controls = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

const Button = styled.button`
  &:not(:last-child) {
    margin-right: 10px;
  }
`

const Devtools: FC<DevtoolsProps> = props => {
  const { onBack, onNext, onReset } = props
  return (
    <Root>
      <div>Developer Controls</div>
      <Controls>
        <Button onClick={onBack}>Back</Button>
        <Button onClick={onNext}>Next</Button>
        <Button onClick={onReset}>Reset</Button>
      </Controls>
    </Root>
  )
}

export { Devtools }
