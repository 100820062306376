import { AssetType } from '~models/common'
import { AccountDetails, AssetPrice, WalletAsset } from '~models/portfolio'
import { Data } from './types'

const byAssetType =
  (type: AssetType) =>
  ({ assetType }: AssetPrice) =>
    assetType === type

export const getAssetPrice = (
  { type }: WalletAsset,
  prices: AssetPrice[],
): number => prices.find(byAssetType(type))?.price ?? 1

export const getAssetTotal = (asset: WalletAsset, prices: AssetPrice[]) =>
  asset.units * getAssetPrice(asset, prices)

export const getWalletTotal = (assets: WalletAsset[], prices: AssetPrice[]) =>
  assets.reduce((acc, val) => acc + getAssetTotal(val, prices), 0)

export const toTableData =
  (prices: AssetPrice[]) =>
  ({ accountNumber, type, walletAssets }: AccountDetails): Data[] =>
    walletAssets.map(asset => ({
      accountNumber,
      accountType: type,
      category: asset.assetCategoryName,
      cost: getAssetPrice(asset, prices),
      isAsset: asset.type !== AssetType.Cash,
      name: asset.assetName,
      units: asset.units,
      value: getAssetTotal(asset, prices),
    }))
