/* eslint-disable jsx-a11y/label-has-associated-control */
import { zodResolver } from '@hookform/resolvers/zod'
import { rem } from 'polished'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import Button from '~components/button'
import { Field, FieldHelp, FieldInput } from '~components/fields'
import { styled } from '~styles'
import { visuallyHidden } from '~styles/mixins'

const schema = z.object({
  code: z.string().length(6, { message: 'Invalid code' }),
})
type FormValues = z.infer<typeof schema>

const Form = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;

  & > * + * {
    margin-left: ${rem(10)};
  }
`

const OneTimeCodeInput = styled(FieldInput)`
  > input {
    font-size: ${rem(18)};
    font-weight: 600;
    letter-spacing: 0.2em;
    padding: 0;
    text-align: center;
    margin: 0 auto;
    max-width: 250px;
  }
`

export function EnterCodeForm({
  isSubmitting,
  onSubmit,
}: {
  isSubmitting?: boolean
  onSubmit?: SubmitHandler<FormValues>
}) {
  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
  })
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <Form noValidate onSubmit={handleSubmit(data => onSubmit?.(data))}>
        <div css={{ marginBottom: 15, textAlign: 'center' }}>
          <Field isRequired name="code">
            <label>
              <span css={visuallyHidden}>one-time-code</span>
              <OneTimeCodeInput
                autoComplete="one-time-code"
                placeholder="000000"
              />
            </label>
            <FieldHelp />
          </Field>
        </div>
        <Button isLoading={isSubmitting} type="submit" variant="primary">
          Submit
        </Button>
      </Form>
    </FormProvider>
  )
}
