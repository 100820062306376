import { em } from 'polished'
import type { FC } from 'react'

import { LockIcon } from '~assets/icons'
import { Tooltip } from '~components/tooltip'
import { styled } from '~styles'
import { unselectable } from '~styles/mixins'

const Root = styled.div`
  align-items: center;
  display: flex;
`

const Hint = styled.div`
  ${unselectable}
  color: ${({ theme }) => theme.blue};
  cursor: default;
  font-size: ${em(12)};
  flex: 1;
`

const SsnTooltip: FC = props => (
  <Tooltip
    content={`Your Social Security Number is used to confirm your identification and establish your account, per federal regulations.`}
  >
    <Root {...props}>
      <LockIcon css={{ marginRight: 6 }} />
      <Hint>Why do we need this?</Hint>
    </Root>
  </Tooltip>
)

export { SsnTooltip }
