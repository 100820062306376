export enum LeadStatus {
  Pending = 12,
  DuplicateTrash = 13,
  NotReferrable = 14,
  Contacting = 17,
  NewLead = 19,
  Closed = 20,
  SoldDeal = 21,
  Trash = 34,
  SentKit = 40,
  OrdersAwaitingPayment = 41,
  Dead = 42,
  SentIraPaperwork = 47,
  SignedIraPaperwork = 48,
  ReloadLead = 57,
  EmailSubscription = 62,
  DigitalIraNewLead = 63,
}
