import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Routes, Route } from 'react-router-dom'

import { useLeadQuery } from '~api/lead'
import ErrorFallback from '~components/error-fallback'
import Layout from '~components/layouts/side-nav'
import { loadConfig } from '~config.client'
import ResetPasswordScreen from '~screens/account/reset-password'
import ActivateScreen from '~screens/activate'
import ApplicationScreen from '~screens/application'
import StatementsScreen from '~screens/statements'
import NotFoundScreen from '~screens/not-found'
import SummaryScreen from '~screens/summary'
import VerifyScreen from '~screens/verify'
import { PhoneVerificationDialog } from '~components/phone-verification-dialog'
import ApplicationCompleteScreen from '~screens/application/complete'

const AuthenticatedApp = () => {
  const { data: lead } = useLeadQuery()
  const showPhoneVerificationDialog =
    loadConfig().features.phoneVerification && lead && !lead.isPhoneVerified

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout>
        <Routes>
          <Route path="/" element={<SummaryScreen />} />
          <Route path="/application" element={<ApplicationScreen />} />
          <Route
            path="/application/complete"
            element={<ApplicationCompleteScreen />}
          />
          <Route path="/activate" element={<ActivateScreen />} />
          <Route
            path="/account/reset-password"
            element={<ResetPasswordScreen />}
          />
          <Route path="/statements" element={<StatementsScreen />} />
          <Route path="/verify" element={<VerifyScreen />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </Layout>
      {showPhoneVerificationDialog && (
        <PhoneVerificationDialog phone={lead.phone ?? ''} />
      )}
    </ErrorBoundary>
  )
}

export default AuthenticatedApp
