import { ChangeEvent, FC, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useUIDSeed } from 'react-uid'

import { Radio } from '~components/inputs/radio'
import { Product } from '~models/common'
import { Lead } from '~models/leads/lead'
import { styled } from '~styles'
import { controlLabel } from '~styles/mixins'
import {
  PrimeAccountForm,
  FormValues as PrimeFormValues,
} from './prime-account-form'
import {
  SaverAccountForm,
  FormValues as SaverFormValues,
} from './saver-account-form'

export interface AccountFormProps {
  onSubmit?: SubmitHandler<PrimeFormValues | SaverFormValues>
  lead?: Lead
  isLoading?: boolean
}

const Root = styled.div`
  min-width: 600px;
`

const Label = styled.div`
  ${controlLabel}
`

const RadioGroup = styled.div`
  display: flex;

  > div:not(:last-of-type) {
    margin-right: 20px;
  }
`

const AccountForm: FC<AccountFormProps> = ({
  onSubmit,
  lead,
  isLoading = false,
  ...props
}) => {
  const [product, setProduct] = useState<number>(Product.Prime)
  const seed = useUIDSeed()

  const handleProductChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProduct(Number(e.target.value))
  }

  const primeDefaultValues = {
    accountType: lead?.accountType,
    accountValue: lead?.accountValue,
    custodian: lead?.custodian,
    releasingCustodianAccountNumber: lead?.releasingCustodianAccountNumber,
    investAmount: lead?.investAmount,
    isInterestedInEarn: lead?.isInterestedInEarn,
  }

  const saverDefaultValues = {
    depositRecurrence: lead?.depositRecurrence,
    investAmount: lead?.investAmount,
    isInterestedInEarn: lead?.isInterestedInEarn,
  }

  return (
    <Root {...props}>
      <div
        aria-labelledby={seed('product-label')}
        css={{ marginBottom: 25 }}
        role="radiogroup"
      >
        <Label id={seed('product-label')}>
          How would you like to fund your account?
        </Label>
        <RadioGroup>
          <Radio
            checked={product === Product.Prime}
            id={seed('product-1')}
            name="product"
            onChange={handleProductChange}
            value={Product.Prime}
            variant="block"
          >
            Transfer from a retirement account
          </Radio>
          <Radio
            checked={product === Product.Saver}
            id={seed('product-2')}
            name="product"
            onChange={handleProductChange}
            value={Product.Saver}
            variant="block"
          >
            Add funds from a bank account
          </Radio>
        </RadioGroup>
      </div>
      {product === Product.Prime ? (
        <PrimeAccountForm
          defaultValues={primeDefaultValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      ) : (
        <SaverAccountForm
          defaultValues={saverDefaultValues}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
    </Root>
  )
}

export { AccountForm }
