import { z } from 'zod'

import { Product } from '~models/common'
import { currencyMask } from '~utils/masker'
import { castToNumber } from '~utils/validation'

const baseSchema = z.object({
  investAmount: z.preprocess(currencyMask.transform, z.number().positive()),
})

export const primeFormSchema = baseSchema.extend({
  accountType: z.string(),
  accountValue: z.preprocess(currencyMask.transform, z.number().positive()),
  custodian: z.string().nonempty({ message: 'Custodian is required' }),
  releasingCustodianAccountNumber: z.string().optional(),
  product: z.literal(Product.Prime),
})

export const saverFormSchema = baseSchema.extend({
  depositRecurrence: z.preprocess(castToNumber, z.number()),
  product: z.literal(Product.Saver),
})
