import styled from '@emotion/styled'
import { FC } from 'react'

import Select, { SelectProps } from '~components/fields/select'
import { option } from '~styles/mixins'

export type AccountTypeSelectProps = Omit<SelectProps, 'children'>

const Option = styled.option`
  ${option}
`

const accountTypes = [
  { label: 'Traditional IRA', value: 'TraditionalIra' },
  { label: 'Roth IRA', value: 'RothIra' },
  { label: 'SEP IRA', value: 'SepIra' },
  { label: '401k', value: 'Regular401K' },
  { label: 'Beneficiary IRA', value: 'BeneficiaryIra' },
  { label: 'Other', value: 'Other' },
]

const AccountTypeSelect: FC<AccountTypeSelectProps> = props => {
  return (
    <Select {...props}>
      {accountTypes.map(({ label, value }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  )
}

export { AccountTypeSelect }
