import { isValid, parse } from 'date-fns'
import { z } from 'zod'

import { mask, dateFormatClient } from '~utils/masker'

export const formSchema = z.object({
  firstName: z.string().nonempty({ message: 'First name is required' }),
  middleName: z.string().optional(),
  lastName: z.string().nonempty({ message: 'Last name is required' }),
  dateOfBirth: z
    .string()
    .nonempty({ message: 'Date of birth is required' })
    .transform<string>(mask.date.transform)
    .refine(val => isValid(parse(val, dateFormatClient, new Date())), {
      message: 'Invalid date of birth',
    }),
  city: z.string().nonempty({ message: 'City is required' }),
  address: z.string().nonempty({ message: 'Address is required' }),
  state: z.string().nonempty({ message: 'State is required' }),
  zipCode: z.string().nonempty({ message: 'Zip code is required' }),
  ein: z
    .string()
    .nonempty({ message: 'SSN is required' })
    .transform<string>(mask.socialSecurity.transform)
    .refine(val => val.length === 11, {
      message: 'Invalid SSN',
    }),
  marriedStatus: z.string().nonempty({ message: 'Marital Status is required' }),
})
