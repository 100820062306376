import { Combobox as BaseCombobox, ComboboxProps } from '@reach/combobox'
import styles from '@reach/combobox/styles.css'
import useStyles from 'isomorphic-style-loader/useStyles'
import { forwardRef } from 'react'

const Combobox = forwardRef<HTMLDivElement, ComboboxProps>((props, ref) => {
  useStyles(styles)
  return <BaseCombobox ref={ref} {...props} />
})
Combobox.displayName = 'Combobox'

export type { ComboboxProps } from '@reach/combobox'
export { Combobox }
