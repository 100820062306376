import type { FC, ReactNode } from 'react'
import { em } from 'polished'

import Flag from '~components/application-wizard/assets/flag.svg'
import { styled } from '~styles'

interface FooterProps {
  disclosure: ReactNode
}

const Root = styled.footer`
  padding: 40px 0 20px;
  margin: 0 auto;
  max-width: 1000px;
`

const Title = styled.div`
  display: flex;
  font-size: ${em(14)};
  font-weight: 600;
  margin-bottom: 10px;
`

const Footer: FC<FooterProps> = ({ disclosure }) => {
  return (
    <Root>
      <Title>
        <Flag css={{ marginRight: 10, verticalAlign: 'top' }} />
        Please read the most important disclosures below.
      </Title>
      {disclosure}
    </Root>
  )
}

export { Footer }
