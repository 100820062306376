import styled from '@emotion/styled'
import type { FC } from 'react'

import Select, { SelectProps } from '~components/fields/select'
import { option } from '~styles/mixins'
import { MARITAL_STATUS } from '~constants/marital-status'
import { formatEnumName } from '~utils/format'

export type MaritalStatusSelectProps = Omit<SelectProps, 'children'>

const Option = styled.option`
  ${option}
`

const MaritalStatusSelect: FC<MaritalStatusSelectProps> = props => {
  return (
    <Select {...props}>
      <Option value="">Select</Option>
      {MARITAL_STATUS.map(value => (
        <Option key={value} value={value}>
          {formatEnumName(value)}
        </Option>
      ))}
    </Select>
  )
}

export { MaritalStatusSelect }
