import styled from '@emotion/styled'
import type { FC } from 'react'

import Select, { SelectProps } from '~components/fields/select'
import { STATE_CODES } from '~constants/state'
import { option } from '~styles/mixins'

export type StateSelectProps = Omit<SelectProps, 'children'>

const Option = styled.option`
  ${option}
`

const StateSelect: FC<StateSelectProps> = props => {
  return (
    <Select {...props}>
      <Option value=""></Option>
      {STATE_CODES.map(value => (
        <Option key={value} value={value}>
          {value}
        </Option>
      ))}
    </Select>
  )
}

export { StateSelect }
