import React, { Dispatch, forwardRef, useReducer } from 'react'

import { useControlledStateSync } from '~hooks/use-controlled-state-sync'
import { isNil } from '~utils/functional'

type OnChangeFn = (val: string) => void

type Event =
  | { type: 'SET_VALUE'; callback?: OnChangeFn; value: string }
  | { type: 'RESET' }

type State = { onValueChange?: OnChangeFn; value?: string }

export interface PresetInputProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  defaultValue?: string
  onChange?: OnChangeFn
  value?: string
}

const DispatchContext = React.createContext<Dispatch<Event> | undefined>(
  undefined,
)

const StateContext = React.createContext<State | undefined>(undefined)

const reducer: Reducer<State, Event> = (state, event) => {
  switch (event.type) {
    case 'SET_VALUE': {
      const { callback, value } = event

      if (value) {
        callback?.(value)
      }

      return { ...state, value }
    }
    case 'RESET':
      return { ...state, value: undefined }
  }
}

export const PresetInput = forwardRef<HTMLDivElement, PresetInputProps>(
  function PresetInput(
    // eslint-disable-next-line unicorn/no-null
    { children, defaultValue, onChange, value: valueProp, ...props },
    ref,
  ) {
    const { current: isControlled } = React.useRef(!isNil(valueProp))

    const [state, dispatch] = useReducer(reducer, {
      onValueChange: onChange,
      // eslint-disable-next-line unicorn/no-null
      value: (isControlled ? valueProp : defaultValue) ?? undefined,
    })

    useControlledStateSync(valueProp, state.value, () =>
      dispatch({ type: 'SET_VALUE', value: valueProp as string }),
    )

    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <div ref={ref} {...props}>
            {children}
          </div>
        </DispatchContext.Provider>
      </StateContext.Provider>
    )
  },
)
PresetInput.displayName = 'PresetInput'

export const usePresetContext = (): [State, Dispatch<Event>] => {
  const state = React.useContext(StateContext)
  const dispatch = React.useContext(DispatchContext)

  if (!state || !dispatch) {
    throw new Error('usePresetContext must be used within a PresetInput')
  }

  return [state, dispatch]
}
