import styled from '@emotion/styled'
import BaseTippy, { TippyProps } from '@tippyjs/react'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FC } from 'react'
import styles from 'tippy.js/dist/tippy.css'

export type TooltipProps = Omit<TippyProps, 'theme'>

const Tippy = styled(BaseTippy)``

const Tooltip: FC<TooltipProps> = props => {
  useStyles(styles)
  return <Tippy {...props} />
}

export { Tooltip }
