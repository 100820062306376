import { isNil } from './functional'

export const formatEmpty = (val?: any, placeholder = '--'): string =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  isNil(val) || val === '' ? placeholder : val

export const formatNumber = (
  val: number,
  options?: Intl.NumberFormatOptions,
): string => {
  if (typeof val !== 'number') return val ?? ''

  return val.toLocaleString('en-US', options)
}

export const formatCurrency = (val: number): string =>
  formatNumber(val, {
    currency: 'USD',
    style: 'currency',
  })

export const formatEnumName = (text: string): string => {
  return text
    ?.replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5')
    ?.replace('Ira', 'IRA')
}
