import { format, parseISO } from 'date-fns'
import { em, hsl } from 'polished'
import React, { useMemo } from 'react'
import { BiTransfer } from 'react-icons/bi'
import { FiDollarSign, FiMinus } from 'react-icons/fi'
import {
  CellProps,
  Column,
  PluginHook,
  useExpanded,
  useFilters,
  useSortBy,
} from 'react-table'

import {
  TableData,
  TableProvider,
  TableProps,
  Tr as BaseTr,
} from '~components/table'
import { useMedia } from '~hooks/use-media'
import { AccountDetails } from '~models/portfolio'
import { css, getQuery, mq, styled } from '~styles'
import { textNoWrap } from '~styles/mixins'
import { formatCurrency } from '~utils/format'
import { renderDetails } from './details'
import { useExpanderColumn } from './expander'
import BaseHeader from './header'
import { dateRangeFilterFn } from './helpers'
import NoData from './no-data'
import { Data } from './types'

export interface Props extends Omit<TableProps, 'columns' | 'plugins'> {
  accountDetailsData: AccountDetails[]
  data: Data[]
}

const Header = styled(BaseHeader)`
  margin-bottom: 40px;
`

const THead = styled.div`
  padding: 0 5px;
`

const Tr = styled(BaseTr)`
  padding: 0 5px;
`

const Td = styled.div`
  font-size: ${em(12)};
  padding: 15px 0;

  ${mq('md')} {
    font-size: ${em(13)};
  }
`

const AmountText = styled.div<{ isNegative?: boolean }>`
  font-variant-numeric: tabular-nums;
  ${({ isNegative }) =>
    isNegative &&
    css`
      color: ${hsl(1, 0.83, 0.59)};
    `}
`

const TypeIcon = styled.span<{ isNegative?: boolean }>`
  align-items: center;
  background-color: ${({ isNegative, theme }) =>
    isNegative ? theme.grayLighter : theme.primary};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ isNegative, theme }) =>
    isNegative ? theme.textColor : theme.primaryContrast};
  display: inline-flex;
  font-size: ${em(14)};
  height: 18px;
  justify-content: center;
  margin-right: 7px;
  width: 18px;

  ${mq('md')} {
    font-size: ${em(16)};
    height: 24px;
    width: 24px;
  }
`

const TypeText = styled.span`
  ${textNoWrap}
  max-width: 100px;

  ${mq('md')} {
    max-width: initial;
  }
`

const TransactionTypeCell = ({ value, row }: CellProps<Data, string>) => {
  const total = row.original.total
  const isTransfer = /swap/i.test(row.original.type)
  const isNegative = total < 0

  const Icon = isTransfer ? BiTransfer : isNegative ? FiMinus : FiDollarSign

  return (
    <div css={{ alignItems: 'center', display: 'flex' }}>
      <TypeIcon isNegative={isNegative}>
        <Icon />
      </TypeIcon>
      <TypeText>{value}</TypeText>
    </div>
  )
}

const TransactionsTable: React.FC<Props> = ({
  accountDetailsData,
  ...props
}) => {
  const filterTypes = React.useMemo(
    () => ({ dateRange: dateRangeFilterFn }),
    [],
  )

  const columns: Column<Data>[] = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: ({ date }) => parseISO(date),
        filter: 'dateRange',
        sortType: 'datetime',
        width: 80,
        Cell: ({ value }: CellProps<Data, Date>) => (
          <span title={format(value, 'yyyy-MM-dd HH:mm:ss')}>
            {format(value, 'MMM d, yyyy')}
          </span>
        ),
      },
      {
        Header: 'Account',
        accessor: ({ accountNumber }) => accountNumber,
      },
      {
        Header: 'Type',
        accessor: ({ typeName }) => typeName,
        Cell: TransactionTypeCell,
      },
      {
        Header: 'Asset',
        accessor: ({ asset }) => asset,
      },
      {
        Header: 'Units',
        width: 80,
        accessor: ({ units }) => units,
      },
      {
        Header: 'Total',
        accessor: ({ total }) => total,
        width: 70,
        Cell: ({ value }: CellProps<Data, number>) => (
          <AmountText isNegative={value < 0}>
            {formatCurrency(value)}
          </AmountText>
        ),
      },
    ],
    [],
  )

  const responsiveColumns = useMedia([getQuery('md')], [[]], ['Asset', 'Units'])

  const initialState = useMemo(
    () => ({
      filters: [{ id: 'Account', value: accountDetailsData[0]?.accountNumber }],
      hiddenColumns: ['Account', ...responsiveColumns],
      sortBy: [{ id: 'Date', desc: true }],
    }),
    [accountDetailsData, responsiveColumns],
  )

  const plugins: PluginHook<Data>[] = useMemo(
    () => [
      useFilters,
      useSortBy,
      useExpanded,
      hooks => {
        hooks.visibleColumns.push(useExpanderColumn)
      },
    ],
    [],
  )

  return (
    <TableProvider
      columns={columns}
      filterTypes={filterTypes}
      initialState={initialState}
      plugins={plugins}
      {...props}
    >
      <Header
        accountColumnId="Account"
        accountDetailsData={accountDetailsData}
        assetColumnId="Asset"
        dateColumnId="Date"
        typeColumnId="Type"
      />
      <TableData
        isDivided
        noData={NoData}
        renderSubcomponent={({ row }) => renderDetails(row)}
        theadAs={THead}
        tdAs={Td}
        trAs={Tr}
      />
    </TableProvider>
  )
}

export { toTableData } from './helpers'
export default TransactionsTable
