import { useQuery } from 'react-query'

import { SubscriberName } from '~constants'
import { useAuth } from '~context/auth-context'
import { useSnackbars } from '~context/snackbar-context'
import { AllOrders } from '~models/trade'
import { client } from '~utils/api-client'

type FetchLeadOrdersVariables = {
  subscriber: SubscriberName
  leadId: number
}

export const fetchLeadOrders = ({
  leadId,
  subscriber,
}: FetchLeadOrdersVariables) =>
  client<AllOrders>(`subscribers/${subscriber}/leads/${leadId}/lead-orders`)

export const useLeadOrdersQuery = () => {
  const { addAlert } = useSnackbars()
  const { subscriber, claims } = useAuth()

  if (!claims || !subscriber) {
    throw new Error('Not authenticated')
  }

  const variables: FetchLeadOrdersVariables = {
    leadId: Number(claims.LeadId),
    subscriber,
  }

  return useQuery(['leadOrders'], () => fetchLeadOrders(variables), {
    cacheTime: 1000 * 60,
    staleTime: 1000 * 60,
    onError: (error: Error | string) => {
      addAlert(error, { title: 'Failure', variant: 'danger' })
    },
  })
}
