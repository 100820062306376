import { assign, createMachine } from 'xstate'

export const phoneVerificationMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAcAWB7AdmAtANzACcBLAM2IGMBDAF2KwDoKtzCBbABQ2weIgBswAYgDKAUQAqAfQ4AJAPIA5MYhTpYxOllUgAHohwAWABwBOBgFYA7BYBst0wAYAjAGYrjw7asAaEAE8DZ2NHBldHV2cIq1MLUysoiwBfJL80LFwCEnJqLUwmFmJ2LgzeAWERAFUAIQBZAElpOSUVJBBkdU16TB19BBwAJliGFysB22t4hwm-QP7DCJGHBedTQytXAcNTFLTuTKIySlpugsxWTn2GWABXACM2TTpMKCEIUuJMPHQAazAGdLYfCHHInRjMc5FS6lW4PJ6fKAIT7fXLdADajgAujoOho8r0DK5zI5jCsBgkBqSvMZZohnM4GKZjK5rIZxlYYm4LIZdu19sDssc8mcLiUeLDHjRnq8iIR0IQAfxaKR5WwAfyskdUeDCsUrhL4S8kV90NrMBjsW1cV1tG0+jhwlZGRYScYbK5mYY3LZaf0huZbMZnBYBpSvaZXAtkqk+RkBVqwfkwJgaEQAMLoCD-PiCUQ1BrSNPyAAirTUeO6BIQhkMrgYzIs4Uite5AwsFl91kZXo9EU8tmcVi9vMBB0FZoYydThAzWbKuYASmJxIpizIFMocZ18XbEPYnYkYgNHI3Ir4AkEoiMBsGI45TIGTySRxqQULTlP05n-gapQi3h8Jp-OqcaaqCwqfjO37XPckrSsaKKJhaW4VraoB9BMTr2MYbYhKYEZ2IYvrBOYTJujhLirEOOwxqO8bgR+KZfnOv7SkIsryoqyqqiBQJge+jCQbOP6wYaiLIqaSFYihNo9LuCDkhYYThG296BrYnh2J2TprG4IThF4g7DrRr7jomk5MVBc6EHAyYQP+7w8BJwF0fxE5CdBNmwHZCIIZJeTIVa26VvJEyGGEKwJG6piUhGvo4EpJIRjFrjuKGx7jC+oFvu5lnCQwXk+S87GEHKCrIEqNAquwvFjgmEF5Z5tmYPZRoSWagXlrJVYTEpVKOEOqWkhYOHxV2tibA4RJthGLLRns2VmQ107CUI1QAIJpgA0jJO7oXuGkMDWRLONsaxnTSF5+g+DDqVsUSUt60Yxpg37wFapn1acEKilcOZgLtIX7fMOEMG4WyeJs+Esus8WRsYSzehyXoJF682xnxOXmT9UJiiJcJ-i8gNoXoBgjUd5KDSNZ2bB2V0OhslgDnN2wjQNJ5ZZjS3fbq0LYMTcnAwlbiWDY9hOG4Hiw-T6y2EsawDR6E2DlYnN1QxgmNXO-0C1WXihA4A0eCELiTPFtjheDHgeiEbLBmr9ECUmWv43BCK6-JCwIxbji+wkmFMgMxFdmRQYbGYET4cYDtueZHnWc1rVQB7wNOIlQbBiYHoeje8VmLdk2mM4E2GBYp2RjHWPLcxANBahgukwpZfKY4qkPsYGmlz69PBHWrfFySbJxGLqsmYtX2MI5tddXtjf0jER2rEPCTOOM0tzBljLGORVhTbYx775X3Mk9as-2o2TrWHYhuS5455zAl2zywsVjb63N6jykQA */
  createMachine(
    {
      tsTypes: {} as import('./machine.typegen').Typegen0,
      schema: {
        context: {} as { phone: string; requestId: number },
        events: {} as
          | { type: 'SET_PHONE'; phone: string }
          | { type: 'SUBMIT_PHONE'; phone: string }
          | { type: 'SUBMIT_CODE'; code: string }
          | { type: 'RESEND_PHONE' }
          | { type: 'BACK' },
        services: {} as {
          submitPhone: { data: number }
          submitCode: { data: void }
        },
      },
      id: 'phone-verification',
      predictableActionArguments: true,
      initial: 'confirmPhone',
      states: {
        confirmPhone: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SET_PHONE: {
                  actions: 'setPhone',
                },
                SUBMIT_PHONE: {
                  actions: 'setPhone',
                  target: 'submitting',
                },
              },
            },
            submitting: {
              invoke: {
                src: 'submitPhone',
                onDone: [
                  {
                    actions: 'setRequestId',
                    target: '#phone-verification.enterCode',
                  },
                ],
                onError: [
                  {
                    target: 'idle',
                  },
                ],
              },
            },
          },
        },
        enterCode: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SUBMIT_CODE: {
                  target: 'submitting',
                },
                RESEND_PHONE: {
                  target: 'resending',
                },
              },
            },
            submitting: {
              invoke: {
                src: 'submitCode',
                onDone: [
                  {
                    actions: 'notifyPhoneVerified',
                    target: '#phone-verification.done',
                  },
                ],
                onError: [
                  {
                    target: 'idle',
                  },
                ],
              },
            },
            resending: {
              invoke: {
                src: 'submitPhone',
                onDone: [
                  {
                    actions: 'setRequestId',
                    target: 'idle',
                  },
                ],
                onError: [
                  {
                    target: 'idle',
                  },
                ],
              },
            },
          },
          on: {
            BACK: {
              target: 'confirmPhone',
            },
          },
        },
        done: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        setPhone: assign({ phone: (_, event) => event.phone }),
        setRequestId: assign({ requestId: (_, event) => event.data }),
      },
    },
  )
