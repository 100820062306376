import React from 'react'

import BaseFilterSelect, { FilterOption } from '~components/filter-select'
import { useTableContext } from '~components/table'
import { styled } from '~styles'
import { filterSelect } from './mixins'
import { useColumnValues } from './use-column-values'

interface Props {
  columnId: string
}

const FilterSelect = styled(BaseFilterSelect)`
  ${filterSelect}
`

const TypeFilter: React.FC<Props> = ({ columnId, ...props }) => {
  const { setFilter, state } = useTableContext()

  const filter = state.filters.find(({ id }) => id === columnId)
  const filterValue = (filter?.value as string) || 'default'

  const columnValues = useColumnValues(columnId)

  const handleReset = () => setFilter(columnId, undefined)

  const handleChange = (val?: string) =>
    val && val !== 'default' ? setFilter(columnId, val) : handleReset()

  return (
    <FilterSelect
      label="Type"
      placeholder="Select a transaction type"
      onChange={handleChange}
      onReset={handleReset}
      value={filterValue}
      {...props}
    >
      {columnValues.map(val => (
        <FilterOption as="li" key={val} value={val}>
          {val}
        </FilterOption>
      ))}
    </FilterSelect>
  )
}

export default TypeFilter
