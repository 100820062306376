import { hsl } from 'polished'
import React, { useMemo } from 'react'
import {
  CellProps,
  Column,
  PluginHook,
  Row,
  TableState,
  useExpanded,
  useFilters,
  useGroupBy,
  useSortBy,
} from 'react-table'

import { TableData, TableProvider, TableProps } from '~components/table'
import { accountTypeLabels } from '~constants'
import { useMedia } from '~hooks/use-media'
import { getQuery, styled } from '~styles'
import { textNoWrap } from '~styles/mixins'
import { formatCurrency, formatEmpty, formatNumber } from '~utils/format'
import AggregateIcon from './aggregate-icon'
import AssetIcon from './asset-icon'
import { useExpanderColumn } from './expander'
import Header from './header'
import NoData from './no-data'
import TrackLine from './track-line'
import { Data } from './types'

export interface Props extends Omit<TableProps, 'columns' | 'plugins'> {
  title: string
  data: Data[]
}

const THead = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.grayLightest};
`

const AssetName = styled.div`
  align-items: center;
  display: flex;
  padding-left: 20px;
  position: relative;
`

const AggregateValue = styled.span`
  ${textNoWrap}
  font-variant-numeric: tabular-nums;
`

const AssetValue = styled(AggregateValue)`
  color: ${hsl(219, 0.17, 0.52)};
`

const useExpandedStateOverride = (state: TableState<Data>) => {
  return useMemo(() => {
    if (state.groupBy.length > 0) {
      const expanded = { ...state.expanded, 'category:Cash': false }

      return { ...state, expanded }
    }
    return state
  }, [state])
}

const AssetAggregateCell = ({ value }: CellProps<Data>) => (
  <>
    <AggregateIcon css={{ marginRight: 18 }} assetCategory={value as string} />
    <strong css={textNoWrap}>{value}</strong>
  </>
)

const AssetNameCell = ({ row }: CellProps<Data>) => (
  <AssetName>
    <TrackLine />
    <AssetIcon css={{ marginRight: 5 }} assetCategory={row.original.category} />
    <AssetValue css={[{ maxWidth: 125 }]} title={row.original.name}>
      {row.original.name}
    </AssetValue>
  </AssetName>
)

const SumAggregateCell = ({ value }: CellProps<Data>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  if (typeof value !== 'number') return value

  return formatEmpty(value > 0 ? value : undefined)
}

const CurrencyAggregateCell = ({ value }: CellProps<Data>) =>
  value > 0 ? <AggregateValue>{formatCurrency(value)}</AggregateValue> : '--'

const CurrencyCell = ({ value }: CellProps<Data>) => (
  <AssetValue>{formatCurrency(value)}</AssetValue>
)

const assetCountReducer = (acc: number, row: Row<Data>) =>
  row.original?.isAsset ? acc + 1 : acc

const marketValueReducer = (acc: number, row: Row<Data>) =>
  acc + row.original.value ?? 0

const PortfolioTable: React.FC<Props> = ({ data, title, ...props }) => {
  const columns: Column<Data>[] = useMemo(
    () => [
      {
        id: 'category',
        Header: 'Asset',
        accessor: ({ category }: Data) => category,
        Cell: AssetAggregateCell,
        Placeholder: AssetNameCell,
        Footer: 'Total',
      },
      {
        Header: 'Account',
        accessor: ({ accountNumber, accountType }: Data) =>
          `${accountTypeLabels[accountType]} Account: ${accountNumber}`,
      },
      {
        Header: 'Name',
        accessor: ({ name }: Data) => name,
      },
      {
        Header: 'Assets',
        accessor: ({ isAsset }) => (isAsset ? 1 : undefined),
        aggregate: 'sum',
        disableSortBy: true,
        Cell: () => <AssetValue>--</AssetValue>,
        Aggregated: SumAggregateCell,
        Footer: ({ rows }: CellProps<Data>) => {
          const total = React.useMemo(
            () =>
              rows
                .filter(r => r.isGrouped)
                .flatMap(r => r.leafRows)
                .reduce(assetCountReducer, 0),
            [rows],
          )

          return total
        },
      },
      {
        Header: 'Quantity',
        accessor: ({ isAsset, units }: Data) => (isAsset ? units : undefined),
        minWidth: 50,
        width: 50,
        Cell: ({ value }: CellProps<Data>) => (
          <AssetValue>{formatNumber(value)}</AssetValue>
        ),
        Aggregated: () => '--',
      },
      {
        Header: 'Market Value',
        accessor: ({ value }: Data) => value,
        aggregate: 'sum',
        align: 'right',
        Cell: CurrencyCell,
        Aggregated: CurrencyAggregateCell,
        Footer: ({ rows }: CellProps<Data>) => {
          const total = React.useMemo(
            () =>
              rows
                .filter(r => r.isGrouped)
                .flatMap(r => r.leafRows)
                .reduce(marketValueReducer, 0),
            [rows],
          )

          return formatCurrency(total)
        },
      },
      {
        Header: 'Cost',
        accessor: ({ cost, isAsset }: Data) => (isAsset ? cost : undefined),
        aggregate: 'sum',
        align: 'right',
        Cell: CurrencyCell,
        Aggregated: CurrencyAggregateCell,
      },
    ],
    [],
  )

  const responsiveColumns = useMedia(
    [getQuery('md')],
    [[]],
    ['Assets', 'Quantity', 'Cost'],
  )

  const initialState = useMemo(
    () => ({
      groupBy: ['category'],
      hiddenColumns: ['Account', 'Name', ...responsiveColumns],
      sortBy: [{ id: 'Name' }],
    }),
    [responsiveColumns],
  )

  const plugins: PluginHook<Data>[] = useMemo(
    () => [
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      hooks => {
        hooks.useControlledState.push(useExpandedStateOverride)
        hooks.visibleColumns.push(useExpanderColumn)
      },
    ],
    [],
  )

  return (
    <TableProvider
      columns={columns}
      data={data}
      expandAllSubRows
      initialState={initialState}
      plugins={plugins}
      {...props}
    >
      <Header filterColumnId="Account" title="Account Summary" />
      <TableData isStriped noData={NoData} theadAs={THead} />
    </TableProvider>
  )
}

export * from './helpers'
export default PortfolioTable
