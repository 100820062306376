import { em, rem } from 'polished'

import { styled } from '~styles'
import { heading } from '~styles/mixins'
import { ConfirmPhoneForm } from './confirm-phone-form'
import MfaIcon from './mfa-icon.svg'
import MfaIconMSDIRA from './mfa-icon-msdira.svg'
import { loadConfig } from '~config.client'

const Title = styled.h2`
  ${heading(700)}
  font-size: ${rem(24)};
  margin: ${rem(12)} 0;
`

const Description = styled.p`
  font-size: ${rem(14)};
  margin: 0 auto ${rem(20)};
  max-width: ${em(350, 14)};
`

export function ConfirmPhone({
  isSubmitting,
  onSubmit,
  phone,
}: {
  isSubmitting?: boolean
  onSubmit: (phone: string) => void
  phone: string
}) {
  return (
    <div>
      <div className="flex flex-col items-center">
        {loadConfig().siteTheme === 'msdira' ? (
          <MfaIconMSDIRA className="mt-3" />
        ) : (
          <MfaIcon className="mt-3" />
        )}
        <Title id="phone-verification-dialog-title">Secure Your Account</Title>
        <Description>
          Please enter your phone number below. We will send you a SMS with a
          code to enter on the next screen.
        </Description>
      </div>
      <ConfirmPhoneForm
        phone={phone}
        isSubmitting={isSubmitting}
        onSubmit={data => onSubmit(data.phone)}
      />
    </div>
  )
}
