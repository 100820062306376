import { hsl } from 'polished'
import React from 'react'

import { styled } from '~styles'

const color = hsl(221, 0.34, 0.86)

const Line = styled.div`
  background: ${color};
  bottom: 50%;
  left: 8px;
  position: absolute;
  height: 150%;
  width: 1px;
`

const Dot = styled.div`
  background: ${color};
  border-radius: 50%;
  position: absolute;
  top: calc(50% - (8px / 2));
  left: 4px;
  width: 8px;
  height: 8px;
`

const TrackLine: React.FC = () => (
  <>
    <Line />
    <Dot />
  </>
)

export default TrackLine
