import React from 'react'

import { styled } from '~styles'

export type PresetListProps = { children: React.ReactNode }

const Root = styled.div`
  display: flex;

  & > [data-preset-option]:not(:last-child) {
    margin-right: 6px;
  }
`

export const PresetList: React.FC<PresetListProps> = props => (
  <Root data-preset-list="" role="radiogroup" {...props} />
)
