import { em } from 'polished'
import React from 'react'
import { useLocation } from 'react-router-dom'

import Footer from '~components/footer'
import Header from '~components/header'
import { mq, styled } from '~styles'
import BaseVerticalNav from './vertical-nav'
import { loadConfig } from '~config.client'

const Root = styled.div`
  position: relative;

  ${mq('md')} {
    margin-left: ${em(66)};
  }
`

const VerticalNav = styled(BaseVerticalNav)`
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  z-index: 10;

  ${mq('md')} {
    transform: translateX(0);
  }
`

const SideNav: React.FC = ({ children }) => {
  const location = useLocation()

  return (
    <Root>
      {location.pathname !== '/application' && <VerticalNav />}
      <Header />
      {children}
      <Footer css={{ marginTop: 40 }} theme={loadConfig().siteTheme} />
    </Root>
  )
}

export default SideNav
