import { em } from 'polished'
import React from 'react'

import { styled } from '~styles'

const Root = styled.div`
  color: ${({ theme }) => theme.textLightColor};
  font-size: ${em(14)};
  font-weight: 600;
  text-align: center;
`

const NoData: React.FC = () => <Root>No results found</Root>

export default NoData
