import React, { forwardRef } from 'react'
import {
  CellProps,
  PluginHook,
  TableInstance,
  TableOptions,
  useFlexLayout,
  useTable,
} from 'react-table'

import { formatEmpty } from '~utils/format'
import { noop } from '~utils/functional'

type Data = any

type State = {
  instance: TableInstance<Data>
}

export interface TableProviderProps
  extends Pick<
    TableOptions<Data>,
    'children' | 'columns' | 'data' | 'filterTypes' | 'initialState'
  > {
  expandAllSubRows?: boolean
  plugins?: PluginHook<Data>[]
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const defaultInstance: TableInstance<any> = {
  rows: [],
  setFilter: noop,
  setAllFilters: noop,
  state: {
    columnOrder: new Array<string>(),
    columnResizing: {
      columnWidth: 0,
      columnWidths: {},
      headerIdWidths: {},
    },
    expanded: {},
    filters: [],
    globalFilter: undefined,
    groupBy: [],
    rowState: {},
    pageIndex: 0,
    pageSize: 0,
    selectedRowIds: {},
    sortBy: [],
  },
} as any

export const TableContext = React.createContext<State>({
  instance: defaultInstance,
})

export const TableProvider = forwardRef<HTMLDivElement, TableProviderProps>(
  function TableProvider(
    {
      children,
      columns,
      data,
      filterTypes,
      expandAllSubRows = false,
      initialState,
      plugins = [],
      ...props
    },
    ref,
  ) {
    const defaultColumn = React.useMemo(
      () => ({
        minWidth: 30,
        width: 150,
        maxWidth: 200,
        Cell: ({ value }: CellProps<Data>) => formatEmpty(value),
        Placeholder: ({ value }: CellProps<Data>) => formatEmpty(value),
      }),
      [],
    )

    const instance = useTable(
      {
        columns,
        data,
        defaultColumn,
        filterTypes,
        initialState,
        manualExpandedKey: 'expanded',
      },
      useFlexLayout,
      ...plugins,
    )

    const { toggleAllRowsExpanded } = instance

    React.useEffect(() => {
      toggleAllRowsExpanded?.(expandAllSubRows)
    }, [expandAllSubRows, toggleAllRowsExpanded])

    return (
      <TableContext.Provider value={{ instance }}>
        <div ref={ref} {...props}>
          {children}
        </div>
      </TableContext.Provider>
    )
  },
)
TableProvider.displayName = 'TableProvider'

export const useTableContext = (): State['instance'] => {
  const state = React.useContext(TableContext)

  if (!state) {
    throw new Error('useTableContext must be used within a TableProvider')
  }

  return state.instance
}
