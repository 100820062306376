/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { zodResolver } from '@hookform/resolvers/zod'
import type { FC } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { FaChevronLeft } from 'react-icons/fa'

import {
  callToAction,
  footer,
  row,
} from '~/components/application-wizard/mixins'
import Button from '~components/button'
import { Field, FieldHelp, FieldInput, FieldLabel } from '~components/fields'
import { styled } from '~styles'
import { fieldHelp } from '~styles/mixins'
import { mask } from '~utils/masker'
import { formSchema } from './schema'
import { SsnTooltip } from './ssn-tooltip'
import { StateSelect } from './state-select'
import { noop } from '~utils/functional'
import { pickNonNullValues } from '~utils/object'
import { MaritalStatusSelect } from '~components/application-wizard/account-holder-step/account-holder-form/marital-status-select'

export type FormValues = z.infer<typeof formSchema>

export interface AccountHolderFormProps {
  defaultValues?: Partial<FormValues>
  onSubmit?: SubmitHandler<FormValues>
  onBack?: () => void
  isLoading?: boolean
}

const Form = styled.form`
  min-width: 600px;
`
const Help = styled.div`
  ${({ theme }) => fieldHelp(theme.danger)}
`
const Footer = styled.div`
  ${footer}
`
const CallToAction = styled(Button)`
  ${callToAction}
`
const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const GoToPrevious = styled.p`
  gap: 4px;
  font-size: 16px;
  color: #999999;
  align-items: center;
  display: flex;
  flex: 1;
  cursor: pointer;
`

const getDefaultValues = ({
  dateOfBirth,
  ...values
}: Partial<FormValues> = {}) =>
  pickNonNullValues({
    dateOfBirth: dateOfBirth ? mask.date.maskDefault(dateOfBirth) : undefined,
    ...values,
  } as Partial<FormValues>)

const AccountHolderForm: FC<AccountHolderFormProps> = ({
  defaultValues,
  onSubmit,
  onBack = noop,
  isLoading = false,
  ...props
}) => {
  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(defaultValues),
    resolver: zodResolver(formSchema),
  })
  const {
    formState: { errors },
    handleSubmit,
  } = methods

  const isFormValid = Object.keys(errors).length === 0

  return (
    <FormProvider {...methods}>
      <Form
        noValidate
        onSubmit={handleSubmit(data => onSubmit?.(data))}
        {...props}
      >
        <div css={[row, { display: 'flex' }]}>
          <Field css={{ flex: 1, marginRight: 20 }} name="firstName">
            <FieldLabel>First Name</FieldLabel>
            <FieldInput />
            <FieldHelp />
          </Field>
          <Field css={{ flex: 1, marginRight: 20 }} name="middleName">
            <FieldLabel>Middle Name</FieldLabel>
            <FieldInput />
            <FieldHelp />
          </Field>
          <Field css={{ flex: 1 }} name="lastName">
            <FieldLabel>Last Name</FieldLabel>
            <FieldInput />
            <FieldHelp />
          </Field>
        </div>
        <div css={[row, { display: 'flex' }]}>
          <Field css={{ flex: 1 }} name="address">
            <FieldLabel>Address</FieldLabel>
            <FieldInput />
            <FieldHelp />
          </Field>
        </div>
        <div css={[row, { display: 'flex' }]}>
          <Field css={{ flex: 1.8, marginRight: 20 }} name="city">
            <FieldLabel>City</FieldLabel>
            <FieldInput />
            <FieldHelp />
          </Field>
          <Field css={{ flex: 1.2, marginRight: 20 }} name="state">
            <FieldLabel>State</FieldLabel>
            <StateSelect />
            <FieldHelp />
          </Field>
          <Field css={{ flex: 1 }} name="zipCode">
            <FieldLabel>Zip</FieldLabel>
            <FieldInput />
            <FieldHelp />
          </Field>
        </div>
        <div css={[row, { display: 'flex' }]}>
          <Field css={{ flex: 1, marginRight: 20 }} name="dateOfBirth">
            <FieldLabel>Date of Birth</FieldLabel>
            <FieldInput
              onChange={mask.date.onChange}
              placeholder="mm/dd/yyyy"
            />
            <FieldHelp />
          </Field>
          <Field css={{ flex: 1, marginRight: 20 }} name="ein">
            <FieldLabel>Social Security Number</FieldLabel>
            <FieldInput
              onChange={mask.socialSecurity.onChange}
              placeholder="000-00-0000"
            />
            <FieldHelp />
            <SsnTooltip css={{ marginTop: 6 }} />
          </Field>
          <Field css={{ flex: 1 }} name="marriedStatus">
            <FieldLabel>Marital Status</FieldLabel>
            <MaritalStatusSelect />
            <FieldHelp />
          </Field>
        </div>
        <Footer>
          {!isFormValid && (
            <Help css={{ marginBottom: 10 }}>
              Please correct any errors before continuing.
            </Help>
          )}
          <ButtonsContainer>
            <GoToPrevious onClick={onBack}>
              <FaChevronLeft /> Previous step
            </GoToPrevious>
            <CallToAction
              isDisabled={!isFormValid || isLoading}
              variant="primary"
            >
              Save & Continue
            </CallToAction>
            <div css={{ flex: 1 }} />
          </ButtonsContainer>
        </Footer>
      </Form>
    </FormProvider>
  )
}

export { AccountHolderForm }
