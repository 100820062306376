import { useMutation, useQueryClient } from 'react-query'
import { z } from 'zod'

import { useSnackbars } from '~context/snackbar-context'
import { Product } from '~models/common'
import { client } from '~utils/api-client'

// TODO: move shared schemas to common package
const BaseRequest = z.object({
  investAmount: z.number().positive(),
  isInterestedInEarn: z.boolean().optional(),
})

const PrimeAccountRequest = BaseRequest.extend({
  accountType: z.string(),
  accountValue: z.number().positive(),
  custodian: z.string(),
  custodianAccountNumber: z.string().optional(),
  product: z.literal(Product.Prime),
})

const SaverAccountRequest = BaseRequest.extend({
  depositRecurrence: z.number(),
  product: z.literal(Product.Saver),
})

export const AccountRequest = z.discriminatedUnion('product', [
  PrimeAccountRequest,
  SaverAccountRequest,
])

export type AccountRequest = z.infer<typeof AccountRequest>

const submitAccount = async (data: AccountRequest): Promise<void> =>
  client('v1/application/account/submit', { data })

export const useSubmitAccountMutation = ({
  onSuccess,
}: {
  onSuccess: () => void
}) => {
  const queryClient = useQueryClient()
  const { addAlert } = useSnackbars()

  return useMutation(submitAccount, {
    onSuccess: () => {
      onSuccess()
      return queryClient.invalidateQueries('lead')
    },
    onError: (error: Error) => {
      addAlert(error, { title: 'Failure', variant: 'danger' })
    },
  })
}
