import { em } from 'polished'
import React from 'react'

import { useTrackPageView } from '~hooks/use-track-page-view'
import { styled } from '~styles'
import { heading } from '~styles/mixins'

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20vh;
  justify-content: center;
`

const Title = styled.h3`
  ${heading()};
  font-size: ${em(24)};
  margin-bottom: ${em(10)};
`

const NotFound: React.FC = () => {
  useTrackPageView('Not found')
  return (
    <Root>
      <Title>Page Not Found</Title>
      <div>Sorry, but the page you were trying to view does not exist.</div>
    </Root>
  )
}

export default NotFound
