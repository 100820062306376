import React from 'react'

import SupportIcon from './support-icon.svg'
import ebookAsset from './ebook-asset.png'

export interface Props {
  downloadLinkUrl: string
  supportEmail: string
  supportPhone: string
  theme?: string
}

export function DashboardWidgets({
  downloadLinkUrl,
  supportEmail,
  supportPhone,
  theme,
}: Props) {
  if (theme !== 'msdira') {
    return null
  }

  return (
    <div className="flex items-center space-x-5">
      <div className="flex h-[250px] flex-1 flex-col items-center space-y-5 rounded-md border border-solid border-zinc-300 p-5">
        <p className="text-lg font-medium">Expert Support</p>
        <p className="text-center text-xs text-neutral-800">
          Our team of experts are available to help with all of your questions!
        </p>
        <strong className="text-[10px]">6:00 AM - 5:00 PM PST</strong>
        <div className="flex items-center space-x-5">
          <SupportIcon />
          <div className="flex flex-col space-y-1">
            <p className="text-xs font-medium">
              Phone: <a href={`tel:+${supportPhone}`}>{supportPhone}</a>
            </p>
            <p className="text-xs font-medium">
              Email: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </p>
          </div>
        </div>
      </div>
      <div className="flex h-[250px] flex-[0_0_600px] items-center rounded-md border border-solid border-zinc-300 p-5">
        <img alt="Ebook Asset" src={ebookAsset} />
        <div className="flex flex-col items-center space-y-5">
          <p className="text-lg font-medium">Knowledge Base</p>
          <p className="text-center text-xs text-neutral-800">
            Download the ultimate guide to self-directed IRAs today.
          </p>
          <a
            className="rounded-lg bg-lime-600 py-3 px-5 font-bold text-white"
            download
            href={downloadLinkUrl}
            role="button"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  )
}
