import { FC } from 'react'

import { useSubmitAccountHolderMutation } from '~api/application/account-holder'
import {
  step,
  stepBanner,
  stepTitle,
} from '~components/application-wizard/mixins'
import { styled } from '~styles'
import { ActiveStepComponent } from '../types'
import { AccountHolderForm } from './account-holder-form'

type AccountHolderStepProps = ActiveStepComponent

const Root = styled.div`
  ${step}
`

const Banner = styled.div`
  ${stepBanner}
`

const Title = styled.h2`
  ${stepTitle}
`
const ErrorText = styled.div`
  color: ${({ theme }) => theme.danger};
`

const AccountHolderStep: FC<AccountHolderStepProps> = ({
  lead,
  onNext,
  onBack,
}) => {
  const {
    mutate: submitAccountHolder,
    isLoading,
    isError,
    error,
  } = useSubmitAccountHolderMutation({ onSuccess: onNext })

  const defaultValues = {
    address: lead?.address,
    city: lead?.city,
    dateOfBirth: lead?.dateOfBirth,
    firstName: lead?.firstName,
    lastName: lead?.lastName,
    middleName: lead?.middleName,
    state: lead?.state,
    zipCode: lead?.zipCode,
    marriedStatus: lead?.marriedStatus,
  }

  return (
    <Root>
      <Banner>
        You've confirmed your account information! Now complete your personal
        information.
      </Banner>
      <Title>Your Information</Title>
      {isError && <ErrorText role="alert">{error?.message}</ErrorText>}
      <AccountHolderForm
        defaultValues={defaultValues}
        onSubmit={data => submitAccountHolder(data)}
        onBack={onBack}
        isLoading={isLoading}
      />
    </Root>
  )
}

export { AccountHolderStep }
