import { yupResolver } from '@hookform/resolvers/yup'
import { em } from 'polished'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import * as y from 'yup'

import { useLeadQuery } from '~api/lead'
import { resetPassword } from '~auth'
import BaseButton from '~components/button'
import {
  Field as BaseField,
  FieldHelp,
  FieldInput,
  FieldLabel,
} from '~components/fields'
import { useSnackbars } from '~context/snackbar-context'
import { LeadStatus } from '~models/leads/lead-status'
import { styled } from '~styles'
import { heading } from '~styles/mixins'

export type FormData = {
  firstFiveSocial?: string
  newPassword: string
  passwordConfirm: string
  token: string
  zipCode?: string
}

const schema = y.object().shape({
  newPassword: y.string().required().min(7),
  passwordConfirm: y
    .string()
    .required()
    .oneOf([y.ref('newPassword')], 'passwords do not match'),
})

const securitySchema = schema.shape({
  firstFiveSocial: y.string().required().length(5),
  zipCode: y.string().required().length(5),
})

export interface Props {
  email: string
  onSuccess?: () => void
}

const Title = styled.div`
  ${heading()}
  font-size: ${em(14)};
`

const Field = styled(BaseField)`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Spacer = styled.span`
  align-self: flex-end;
  font-size: ${em(12)};
  font-weight: 600;
  margin: 0 20px 10px;
`

const Button = styled(BaseButton)`
  font-weight: 600;
  margin-top: 10px;
  width: 100%;
`

const SmallText = styled.p`
  font-size: ${em(12)};
  margin-top: 5px;
`

const PasswordResetForm: React.FC<Props> = ({ email, onSuccess, ...props }) => {
  const [searchParams] = useSearchParams()
  const { addAlert } = useSnackbars()
  const { data: lead } = useLeadQuery()
  const showSecurity =
    lead?.status &&
    [LeadStatus.SignedIraPaperwork, LeadStatus.SoldDeal].includes(lead.status)

  const methods = useForm<FormData>({
    resolver: yupResolver(showSecurity ? securitySchema : schema),
  })

  const { mutate, status } = useMutation(resetPassword, {
    onSuccess,
    onError: (error: string) => {
      addAlert(error, {
        title: 'Failed to reset password',
        variant: 'danger',
      })
    },
  })

  const onSubmit = methods.handleSubmit(data =>
    mutate({ request: { email, ...data } }),
  )

  return (
    <FormProvider {...props} {...methods}>
      <form noValidate onSubmit={onSubmit}>
        <Field name="token">
          <FieldInput
            data-testid="test-token"
            hidden
            type="text"
            value={searchParams.get('token') ?? undefined}
          />
          <FieldHelp />
        </Field>
        <Field isRequired name="newPassword">
          <FieldLabel>New Password</FieldLabel>
          <FieldInput placeholder="At least 8 characters" type="password" />
          <FieldHelp />
        </Field>
        <Field isRequired name="passwordConfirm">
          <FieldLabel>Confirm Password</FieldLabel>
          <FieldInput type="password" />
          <FieldHelp />
        </Field>
        {showSecurity && (
          <>
            <div css={{ margin: `20px 0 10px` }}>
              <Title>Security Challenge</Title>
              <SmallText>
                Please enter first 5 digits of the primary account holder's
                Social Security Number and a zip code of the primary address of
                the account
              </SmallText>
            </div>
            <Row>
              <BaseField css={{ flex: 1 }} isRequired name="firstFiveSocial">
                <FieldLabel>First 5 of SSN</FieldLabel>
                <FieldInput placeholder="00000" type="password" />
                <FieldHelp />
              </BaseField>
              <Spacer>And</Spacer>
              <BaseField css={{ flex: 1 }} isRequired name="zipCode">
                <FieldLabel>Zip Code</FieldLabel>
                <FieldInput placeholder="12345" />
                <FieldHelp />
              </BaseField>
            </Row>
          </>
        )}
        <Button
          isLoading={status === 'loading'}
          type="submit"
          variant="tertiary"
        >
          Set New Password
        </Button>
      </form>
    </FormProvider>
  )
}

export default PasswordResetForm
