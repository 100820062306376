import { em } from 'polished'
import React from 'react'
import { Link } from 'react-router-dom'

import { useLeadQuery } from '~api/lead'
import { usePortfolioQuery } from '~api/portfolio'
import { DashboardWidgets } from '~components/dashboard-widgets'
import Loading from '~components/loading'
import { OpenAccount } from '~components/open-account'
import PortfolioTable, {
  getWalletTotal,
  toTableData,
} from '~components/portfolio-table'
import { loadConfig } from '~config.client'
import { useTrackPageView } from '~hooks/use-track-page-view'
import { styled } from '~styles'
import { formatCurrency } from '~utils/format'

const FooterLink = styled(Link)`
  font-size: ${em(13)};
  font-weight: 600;

  &::after {
    content: '➝';
    margin-left: 5px;
  }
`

function AccountSummary({ total }: { total: number }) {
  return (
    <div
      className="flex h-48 flex-col justify-center rounded-lg px-5 py-7 leading-5 md:w-80"
      style={{
        backgroundColor: 'rgba(var(--primary) / 0.15)',
      }}
    >
      <span className="mb-1">All Accounts</span>
      <span className="text-3xl font-bold text-neutral-800">
        {formatCurrency(total)}
      </span>
    </div>
  )
}

const SummaryScreen: React.FC = () => {
  useTrackPageView('Account Summary')

  const { data, error, isError, isLoading } = usePortfolioQuery()

  const { data: lead } = useLeadQuery()

  const total = React.useMemo(() => {
    if (!data) return 0

    return data.accountDetails.reduce(
      (acc, { walletAssets }) =>
        acc + getWalletTotal(walletAssets, data.assetPrices),
      0,
    )
  }, [data])

  const tableData = React.useMemo(() => {
    if (!data) return []

    return data.accountDetails.flatMap(toTableData(data.assetPrices))
  }, [data])

  return (
    <main className="relative mx-auto mt-4 flex max-w-5xl flex-col space-y-5 px-4 md:mt-9">
      <div className="flex flex-col space-y-5 md:flex-row md:items-center md:space-x-5 md:space-y-0">
        {lead && (
          <OpenAccount
            leadMilestones={lead.leadMilestones}
            theme={loadConfig().siteTheme}
          />
        )}
        <AccountSummary total={total} />
      </div>
      <DashboardWidgets
        downloadLinkUrl="https://myselfdirectedira.com/wp-content/uploads/2023/03/UltimateCheckbookIRAInvestorGuide.pdf"
        supportEmail={loadConfig().contactEmail}
        supportPhone={loadConfig().contactPhone}
        theme={loadConfig().siteTheme}
      />
      <div className="min-h-fit rounded-lg border border-solid border-zinc-300 bg-white p-3 md:py-5 md:px-6">
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <span className="text-rose-500" role="alert">
            {(error as Error).message}
          </span>
        ) : (
          <PortfolioTable data={tableData} title="Account Summary" />
        )}
        <div css={{ marginTop: 10, textAlign: 'right' }}>
          <FooterLink to="statements">View Transaction History</FooterLink>
        </div>
      </div>
    </main>
  )
}

export default SummaryScreen
