import React from 'react'

import BaseFilterSelect, { FilterOption } from '~components/filter-select'
import Presets, { PresetOption } from '~components/presets'
import { useTableContext } from '~components/table'
import { styled } from '~styles'
import { DateInterval, DateIntervalId, getDateInterval } from '~utils/date'
import { filterSelect } from './mixins'

export type DateRangeFilterValue = DateInterval & {
  id: DateIntervalId
}

type DateRangeFilter = {
  label: string
  value: DateRangeFilterValue
}

const dateRanges: Record<DateIntervalId, DateRangeFilter> = {
  last30: {
    label: 'Last 30 Days',
    value: { id: 'last30', ...getDateInterval('last30') },
  },
  last90: {
    label: 'Last 90 Days',
    value: { id: 'last90', ...getDateInterval('last90') },
  },
  quarterToDate: {
    label: 'Quarter To Date',
    value: { id: 'quarterToDate', ...getDateInterval('quarterToDate') },
  },
  yearToDate: {
    label: 'Year To Date',
    value: { id: 'yearToDate', ...getDateInterval('yearToDate') },
  },
  lastYear: {
    label: 'Last Year',
    value: { id: 'lastYear', ...getDateInterval('lastYear') },
  },
}

interface Props {
  columnId: string
}

const FilterSelect = styled(BaseFilterSelect)`
  ${filterSelect}
`

const DateFilter: React.FC<Props> = ({ columnId, ...props }) => {
  const { setFilter, state, setSortBy } = useTableContext()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const filter: DateRangeFilterValue | undefined = state.filters.find(
    ({ id }) => id === columnId,
  )?.value

  const filterValue = filter?.id ?? 'default'

  const handleReset = () => {
    setFilter(columnId, undefined)
    setSortBy([{ id: columnId, desc: true }])
  }

  const handleChange = (val: string) => {
    if (!val || val === 'default') {
      return handleReset()
    }

    setFilter(columnId, dateRanges[val as DateIntervalId].value)
  }

  return (
    <>
      <FilterSelect
        label="Date"
        placeholder="Select a date range"
        onChange={handleChange}
        onReset={handleReset}
        value={filterValue}
        {...props}
      >
        {Object.entries(dateRanges).map(([key, { label }]) => (
          <FilterOption as="li" key={key} value={key}>
            {label}
          </FilterOption>
        ))}
      </FilterSelect>
      <Presets
        onChange={handleChange}
        onReset={handleReset}
        value={filterValue}
      >
        <PresetOption value="default">Recent Transactions</PresetOption>
        <PresetOption value="last30">30 Days</PresetOption>
        <PresetOption value="last90">90 Days</PresetOption>
      </Presets>
    </>
  )
}

export default DateFilter
