import { useQuery } from 'react-query'

import { SubscriberName } from '~constants'
import { useAuth } from '~context/auth-context'
import { useSnackbars } from '~context/snackbar-context'
import { Lead } from '~models/leads/lead'
import { client } from '~utils/api-client'

type FetchLeadVariables = {
  leadId: string
  subscriber: SubscriberName
}

export const fetchLead = ({ leadId, subscriber }: FetchLeadVariables) =>
  client<Lead>(`subscribers/${subscriber}/leads/${leadId}`)

export const useLeadQuery = () => {
  const { addAlert } = useSnackbars()
  const { subscriber, claims } = useAuth()

  if (!claims || !subscriber) {
    throw new Error('Not authenticated')
  }

  const variables = { leadId: claims.LeadId, subscriber }

  return useQuery(['lead'], () => fetchLead(variables), {
    onError: (error: Error | string) => {
      addAlert(error, { title: 'Failure', variant: 'danger' })
    },
  })
}
