import styled from '@emotion/styled'
import type { FC } from 'react'

import { useStepper } from './stepper'
export interface StepProps {
  index: number
  isComplete?: boolean
}

const Indicator = styled.div<{ isComplete: boolean }>`
  background: ${({ isComplete }) => (isComplete ? '#24e2a7' : '#ededed')};
  height: 5px;
  margin-bottom: 10px;

  [data-theme='msdira'] & {
    background: ${({ isComplete }) =>
      isComplete ? 'linear-gradient(to right, #15c175, #24e1a6)' : '#ededed'};
  }
`

const Root = styled.div`
  flex: 1;
  text-align: center;

  &:not(:last-of-type) {
    margin-right: 4px;
  }
`

const Label = styled.span<{ isActive: boolean }>`
  color: ${({ isActive, theme }) => (isActive ? theme.primary : '#b1b1b1')};
  font-size: 12px;
  font-weight: 600;
`

const Step: FC<StepProps> = ({
  children,
  isComplete = false,
  index,
  ...props
}) => {
  const { activeIndex } = useStepper()
  const isActive = activeIndex === index
  const rootProps = isActive ? { ...props, 'aria-current': 'step' } : props

  return (
    <Root {...rootProps}>
      <Indicator aria-hidden="true" isComplete={isComplete} />
      <Label isActive={isActive}>{children}</Label>
    </Root>
  )
}

export { Step }
