import {
  ListboxButton as BaseListboxButton,
  ListboxButtonProps,
  ListboxInput as BaseListboxInput,
  ListboxList,
  ListboxOption as BaseListboxOption,
  ListboxPopover,
} from '@reach/listbox'
import styles from '@reach/listbox/styles.css'
import { positionMatchWidth } from '@reach/popover'
import useStyles from 'isomorphic-style-loader/useStyles'
import { em, hsl, rem } from 'polished'
import React, { forwardRef } from 'react'

import DigitalTrustSmall from '~assets/images/digitaltrust-small.svg'
import { custodianLabels } from '~constants'
import { Custodian } from '~models/common'
import { AccountDetails } from '~models/portfolio'
import { styled } from '~styles'
import { formatCurrency } from '~utils/format'

type OptionsState = Record<
  string,
  {
    balance: number
    custodian: Custodian
    label: string
  }
>

export interface Props extends Omit<ListboxButtonProps, 'children'> {
  className?: string
  defaultValue?: string
  data: AccountDetails[]
  onChange?: (val: string) => void
}

const ListboxInput = styled(BaseListboxInput)`
  &[data-reach-listbox-input] {
    margin-bottom: 12px;
  }
`

const ListboxButton = styled(BaseListboxButton)`
  border-color: ${({ theme }) => theme.grayLighter};
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 65px;
  padding: 0 15px;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.grayLight};
  }
`

const ButtonLabel = styled.div`
  font-size: ${em(14)};
  font-weight: 600;
`

const BalanceText = styled.div`
  font-size: ${em(12)};
  font-weight: 400;
`

const BalanceValue = styled.span`
  color: ${hsl(114, 0.91, 0.36)};
  margin-left: 5px;
`

const ListboxOption = styled(BaseListboxOption)`
  &[data-reach-listbox-option] {
    align-items: center;
    display: flex;
    font-size: ${em(14)};
    padding: 5px 15px;
  }
`

const Arrow = styled.span`
  &::after {
    color: ${({ theme }) => theme.grayLight};
    content: '▾';
    font-size: ${rem(16)};
  }
`

const ButtonMedia = styled.div`
  margin-right: 25px;
  width: 33px;
`

const OptionMedia = styled.div`
  margin-right: 15px;
  width: 20px;
`

const optionsReducer: Reducer<OptionsState, AccountDetails> = (
  acc,
  { custodian, accountNumber, availableCash },
) => ({
  ...acc,
  [accountNumber]: {
    balance: availableCash,
    custodian,
    label: `${custodianLabels[custodian]} Acct ****${accountNumber.slice(-4)}`,
  },
})

const CustodianAccountSelect = forwardRef<HTMLSpanElement, Props>(
  function CustodianAccountSelect(
    { className, data, defaultValue, onChange, ...props },
    ref,
  ) {
    useStyles(styles)

    // eslint-disable-next-line unicorn/prefer-object-from-entries
    const options = data.reduce(optionsReducer, {})

    const initialValue = defaultValue ?? Object.keys(options)[0]

    const [value, setValue] = React.useState<string>(initialValue)

    const handleChange = React.useCallback(
      (val: string) => {
        setValue(val)
        onChange?.(val)
      },
      [onChange],
    )

    return (
      <ListboxInput
        className={className}
        defaultValue={defaultValue}
        onChange={handleChange}
        value={value}
      >
        <ListboxButton arrow={<Arrow />} as="span" ref={ref} {...props}>
          <ButtonMedia>
            <DigitalTrustSmall width="40" />
          </ButtonMedia>
          <div css={{ flex: 1 }}>
            <ButtonLabel>{options[value]?.label}</ButtonLabel>
            <BalanceText>
              <span>Available to trade:</span>
              <BalanceValue>
                {formatCurrency(options[value]?.balance)}
              </BalanceValue>
            </BalanceText>
          </div>
        </ListboxButton>
        <ListboxPopover css={{ zIndex: 10 }} position={positionMatchWidth}>
          <ListboxList>
            {Object.entries(options).map(([key, { label }]) => (
              <ListboxOption as="li" key={key} value={key}>
                <OptionMedia>
                  <DigitalTrustSmall width="25" />
                </OptionMedia>
                <span>{label}</span>
              </ListboxOption>
            ))}
          </ListboxList>
        </ListboxPopover>
      </ListboxInput>
    )
  },
)
CustodianAccountSelect.displayName = 'CustodianAccountSelect'

export default CustodianAccountSelect
