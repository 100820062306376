import { FC } from 'react'

import { useSubmitAccountMutation } from '~api/application/account'
import {
  step,
  stepBanner,
  stepTitle,
} from '~components/application-wizard/mixins'
import { styled } from '~styles'
import { ActiveStepComponent } from '../types'
import { AccountForm } from './account-form'

type AccountStepProps = ActiveStepComponent

const Root = styled.div`
  ${step}
`

const Banner = styled.div`
  ${stepBanner}
`

const Title = styled.h2`
  ${stepTitle}
`
const ErrorText = styled.div`
  color: ${({ theme }) => theme.danger};
`

const AccountStep: FC<AccountStepProps> = ({ lead, onNext }) => {
  const {
    mutate: submitAccount,
    isLoading,
    isError,
    error,
  } = useSubmitAccountMutation({ onSuccess: onNext })

  return (
    <Root>
      <Banner>Get started by entering your account information.</Banner>
      <Title>Your Account Information</Title>
      {isError && <ErrorText role="alert">{error?.message}</ErrorText>}
      <AccountForm
        onSubmit={data => submitAccount(data)}
        lead={lead}
        isLoading={isLoading}
      />
    </Root>
  )
}

export { AccountStep }
