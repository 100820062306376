import { FC } from 'react'

import { useSubmitReviewMutation } from '~api/application/review'
import { useSendPlatformEvent } from '~api/platform-events'
import { step, stepTitle } from '~components/application-wizard/mixins'
import { loadConfig } from '~config.client'
import { impactTracker } from '~impact'
import { styled } from '~styles'
import { noop } from '~utils/functional'
import { ActiveStepComponent } from '../types'
import { ReviewForm } from './review-form'
import { ReviewSummary } from './review-summary'

type ReviewStepProps = ActiveStepComponent

const Root = styled.div`
  ${step}
`
const Title = styled.h2`
  ${stepTitle}
`
const ErrorText = styled.div`
  color: ${({ theme }) => theme.danger};
`

const ReviewStep: FC<ReviewStepProps> = ({
  lead,
  onNext,
  onBack,
  onReset = noop,
}) => {
  const { mutate: sendEvent } = useSendPlatformEvent()
  const {
    mutate: submitReview,
    isLoading,
    isError,
    error,
  } = useSubmitReviewMutation({
    onSuccess: () => {
      sendEvent('application_submitted')
      impactTracker.send({
        leadId: lead?.id.toString() as string,
        type: 'application_submitted',
      })
      onNext()
    },
  })

  return (
    <Root>
      <Title>Review Information</Title>
      <ReviewSummary
        lead={lead}
        onAccountEdit={onReset}
        onAccountHolderEdit={onBack}
      />
      {isError && <ErrorText role="alert">{error?.message}</ErrorText>}
      <ReviewForm
        isLoading={isLoading}
        onBack={onBack}
        onSubmit={data => {
          submitReview({
            ...data,
            eSignatureConsent: Boolean(data.eSignature),
            phone: lead?.phone || '',
          })
        }}
        theme={loadConfig().siteTheme}
      />
    </Root>
  )
}

export { ReviewStep }
