import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import { styled } from '~styles'
import { FileInput } from '~components/inputs/file-input'
import { useUploadDocumentMutation } from '~api/upload-document'
import { FormValues } from '../upload-form'

type UploadDocType = 'licenseFront' | 'licenseBack' | 'retirementStatement'

interface UploadFileProps {
  label: string
  fileType: number
  name: UploadDocType
}

const ErrorText = styled.div`
  color: ${({ theme }) => theme.danger};
`
const ErrorMessage = styled(ErrorText)`
  font-size: 12px;
  text-align: center;
`

const UploadFile: FC<UploadFileProps> = ({ label, name, fileType }) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>()

  const {
    mutate: uploadDocument,
    isLoading,
    error,
  } = useUploadDocumentMutation({
    onSuccess: () => setValue(name, true, { shouldValidate: true }),
  })

  const fileUpload = (file: File): void => {
    if (file && !isLoading) uploadDocument({ file, fileType })
  }

  const isFileError = Object.keys(errors).find(key => key === name)

  const isUploaded = watch(name)

  const color =
    isFileError || error ? 'danger' : isUploaded ? 'success' : 'default'

  return (
    <>
      {error && <ErrorText role="alert">{error?.message}</ErrorText>}
      <FileInput
        accept=".pdf,.png,.jpg,.jpeg"
        label={isLoading ? 'Please Wait...' : label}
        onChange={fileUpload}
        color={color}
        isDisabled={isLoading}
        id={label}
        testId={name}
      />
      {isFileError && <ErrorMessage>Document is required</ErrorMessage>}
    </>
  )
}

export default UploadFile
