import { isWithinInterval } from 'date-fns'
import { Row, FilterType } from 'react-table'

import {
  assetTypeLabels,
  orderTypeLabels,
  swapOrderTypeLabels,
} from '~constants/labels'
import { AssetType } from '~models/common'
import { AllOrders, Order, SwapOrder, Trade, Transaction } from '~models/trade'
import { DateRangeFilterValue } from './header/date-filter'
import { Data, SwapDataType, TransactionData } from './types'

export const dateRangeFilterFn: FilterType<Data> = (
  rows: Row<Data>[],
  ids: string[],
  filterValue: DateRangeFilterValue,
) =>
  rows.filter(row =>
    ids.some(id => isWithinInterval(row.values[id], filterValue)),
  )

const toTransactionData = (t: Transaction): TransactionData => ({
  id: t.id,
  accountNumber: t.custodianAccountNumber,
  asset: t.assetName,
  date: t.transactionDateTime,
  detail: t,
  total: t.amount,
  units: t.quantity,
  type: 'transaction',
  typeName: t.transactionTypeName,
})

const toOrderData =
  (o: Order, type?: SwapDataType) =>
  (t: Trade): Data => ({
    id: t.tradeId,
    accountNumber: o.custodianAccountNumber,
    asset: `${assetTypeLabels[t.assetType]} (${AssetType[t.assetType]})`,
    date: o.createdDateTime,
    detail: { order: o, tradeId: t.tradeId },
    total: t.amount,
    units: t.volume,
    type: type ?? 'order',
    typeName: type
      ? swapOrderTypeLabels[t.tradeType]
      : orderTypeLabels[t.tradeType],
  })

const toSwapOrderRows = (s: SwapOrder): Data[] => [
  ...(s.liquidationOrder?.trades.map(
    toOrderData(s.liquidationOrder, 'swap_liquidation'),
  ) ?? []),
  ...(s.purchaseOrder?.trades.map(
    toOrderData(s.purchaseOrder, 'swap_purchase'),
  ) ?? []),
]

export const toTableData = ({
  purchaseOrders,
  liquidationOrders,
  swapOrders,
  transactions,
}: AllOrders): Data[] => [
  ...liquidationOrders.flatMap(order => order.trades.map(toOrderData(order))),
  ...purchaseOrders.flatMap(order => order.trades.map(toOrderData(order))),
  ...swapOrders.flatMap(toSwapOrderRows),
  ...transactions.map(toTransactionData),
]
