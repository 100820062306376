/* eslint-disable jsx-a11y/label-has-associated-control */
import { zodResolver } from '@hookform/resolvers/zod'
import { rem } from 'polished'
import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'

import Button, { LinkButton } from '~components/button'
import {
  Field,
  FieldHelp,
  FieldInput as BaseFieldInput,
} from '~components/fields'
import BaseInput from '~components/input'
import { styled, css } from '~styles'
import { visuallyHidden } from '~styles/mixins'
import { mask } from '~utils/masker'

const phoneSchema = z
  .string()
  .transform<string>(mask.phone.transform)
  .refine(val => val.length === 10, {
    message: 'Invalid phone number',
  })

const schema = z.object({
  phone: phoneSchema,
})
type FormValues = z.infer<typeof schema>

const inputStyles = css`
  > input {
    font-size: ${rem(18)};
    font-weight: 600;
    padding: 0;
    text-align: center;
  }
`

const Input = styled(BaseInput)`
  ${inputStyles}
`

const FieldInput = styled(BaseFieldInput)`
  ${inputStyles}
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;

  & > * + * {
    margin-left: ${rem(10)};
  }
`

export function ConfirmPhoneForm({
  isSubmitting,
  onSubmit,
  phone,
}: {
  isSubmitting?: boolean
  onSubmit: SubmitHandler<FormValues>
  phone: string
}) {
  const isPhoneValid = phoneSchema.safeParse(phone).success
  const [isEditing, setEditing] = useState(!isPhoneValid)

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      phone: phone ? mask.phone.maskDefault(phone) : undefined,
    },
  })
  const { handleSubmit, register, setValue } = methods

  const onEditClick = () => {
    setEditing(true)
    setValue('phone', '')
  }

  return (
    <FormProvider {...methods}>
      <Form noValidate onSubmit={handleSubmit(data => onSubmit?.(data))}>
        <div css={{ textAlign: 'center' }}>
          {isEditing ? (
            <Field isRequired name="phone">
              <label>
                <span css={visuallyHidden}>Phone</span>
                <FieldInput onChange={mask.phone.onChange} />
              </label>
              <FieldHelp />
            </Field>
          ) : (
            <>
              <input {...register('phone')} type="hidden" value={phone} />
              <label>
                <span css={visuallyHidden}>Phone</span>
                <Input readOnly value={`***-***-${phone.slice(-4)}`} />
              </label>
              <LinkButton onClick={onEditClick}>edit phone number</LinkButton>
            </>
          )}
        </div>
        <Button isLoading={isSubmitting} type="submit" variant="primary">
          Send Code
        </Button>
      </Form>
    </FormProvider>
  )
}
