import { FC } from 'react'
import { format, parseISO } from 'date-fns'

import { Lead } from '~models/leads/lead'
import { styled } from '~styles'
import { formatCurrency, formatEnumName } from '~utils/format'

interface ReviewSummaryProps {
  lead: Lead | undefined
  onAccountEdit: () => void
  onAccountHolderEdit: () => void
}

interface TitleProps {
  label: string
  onEdit: () => void
}

interface TextProps {
  label: string
  value: string
}

export const Container = styled.div`
  padding: 25px 22px 20px 22px;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 20px;
`
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  margin-bottom: 20px;
`
export const Heading = styled.h4`
  font-weight: 600;
`
export const Link = styled.p`
  font-size: 12px;
  color: #1548cd;
  cursor: pointer;
`
export const TextContainer = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
`
export const Label = styled.p`
  min-width: 200px;
  color: #666666;
  font-size: 14px;
`
export const Value = styled.p`
  color: #383838;
  font-weight: 500;
  font-size: 14px;
`

export const Title: FC<TitleProps> = ({ label = '', onEdit }) => (
  <TitleContainer>
    <Heading>{label}</Heading>
    <Link onClick={onEdit}>Edit</Link>
  </TitleContainer>
)

export const Text: FC<TextProps> = ({ label = '', value = '' }) => (
  <TextContainer>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </TextContainer>
)

const ReviewSummary: FC<ReviewSummaryProps> = ({
  lead = {},
  onAccountHolderEdit,
  onAccountEdit,
}) => {
  const {
    accountType = '',
    accountValue = 0,
    address = '',
    custodian = '',
    dateOfBirth: dob = '',
    firstName = '',
    investAmount = 0,
    lastName = '',
    middleName = '',
    releasingCustodianAccountNumber: accountNumber = '',
  } = lead
  const dateOfBirth = dob && format(parseISO(dob), 'MM-dd-yyyy')
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const fullName = `${firstName} ${middleName || ''} ${lastName}`
  const accountTypeName = accountType && formatEnumName(accountType)

  return (
    <>
      <Container>
        <Title
          label="Account Holder Information"
          onEdit={onAccountHolderEdit}
        />
        <Text label="Full Name" value={fullName} />
        <Text label="Address" value={address} />
        <Text label="Date of Birth" value={dateOfBirth} />
        <Text label="Social Security Number" value="*********" />
      </Container>
      <Container>
        <Title label="Transfer Summary" onEdit={onAccountEdit} />
        <Text label="Funding Method" value="" />
        <Text label="Account Type" value={accountTypeName} />
        <Text label="Custodian" value={custodian} />
        <Text label="Account Number" value={accountNumber} />
        <Text label="Total IRA Amount" value={formatCurrency(accountValue)} />
        <Text label="Transfer Amount" value={formatCurrency(investAmount)} />
      </Container>
    </>
  )
}

export { ReviewSummary }
