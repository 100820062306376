import {
  ListboxButton as BaseListboxButton,
  ListboxButtonProps,
  useListboxContext,
} from '@reach/listbox'
import { em, rem } from 'polished'
import React, { forwardRef } from 'react'
import { FiX } from 'react-icons/fi'

import { styled } from '~styles'
import { placeholder, underlineButton } from './mixins'

export interface Props extends ListboxButtonProps {
  label?: string
  onReset?: () => void
}

const ListboxButton = styled(BaseListboxButton)`
  ${underlineButton}
  display: flex;
  width: 100%;
`

const Arrow = styled.span`
  &::after {
    color: ${({ theme }) => theme.grayLighter};
    content: '▾';
    font-size: ${rem(16)};
  }
`

const Reset = styled.button`
  background: transparent;
  border-width: 0;
  color: ${({ theme }) => theme.grayLight};
  font-size: ${rem(16)};
  line-height: 5px;
  padding: 5px 0 5px 5px;

  &:hover {
    color: ${({ theme }) => theme.grayDarker};
  }
`

const Label = styled.div`
  ${placeholder}
  line-height: 1;
  margin-right: 20px;
`

const Value = styled.div`
  font-size: ${em(14)};
  flex: 1;
`

const isDefaultValue = (val?: string | null) => !val || /default/i.test(val)

const Button = forwardRef<HTMLSpanElement, Props>(function Button(
  { children, label = '', onReset, ...props },
  ref,
) {
  const { value, valueLabel } = useListboxContext()
  const isDefault = isDefaultValue(value)

  const postfix = isDefault ? (
    <Arrow />
  ) : (
    <Reset
      aria-label="reset"
      onClick={event => {
        event.stopPropagation()
        onReset?.()
      }}
    >
      <FiX />
    </Reset>
  )

  return (
    <ListboxButton arrow={postfix} as="span" ref={ref} {...props}>
      <Label>{label}</Label>
      {/* eslint-disable-next-line unicorn/no-null */}
      {!isDefault ? <Value>{valueLabel}</Value> : null}
    </ListboxButton>
  )
})
Button.displayName = 'FilterButton'

export default Button
