import { em } from 'polished'
import React from 'react'

import { TableColumnFilterSelect as BaseTableColumnFilterSelect } from '~components/table'
import { mq, styled } from '~styles'
import { heading, textNoWrap } from '~styles/mixins'

export interface Props {
  filterColumnId: string
  title: string
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  ${mq('md')} {
    align-items: center;
    flex-direction: row;
  }
`

const Title = styled.h2`
  ${heading(300)}
  ${textNoWrap}
  font-size: ${em(18)};
  margin-bottom: 15px;

  ${mq('md')} {
    margin-bottom: 0;
  }
`

const Divider = styled.span`
  background: ${({ theme }) => theme.grayLighter};
  display: none;
  height: ${em(25)};
  margin: 0 ${em(35)} 0 ${em(25)};
  width: 1px;

  ${mq('md')} {
    display: inline-block;
  }
`

const TableColumnFilterSelect = styled(BaseTableColumnFilterSelect)`
  width: 100%;

  ${mq('md')} {
    width: 240px;
  }
`

const Header: React.FC<Props> = ({ filterColumnId, title, ...props }) => {
  return (
    <Root {...props}>
      <Title>{title}</Title>
      <Divider />
      <TableColumnFilterSelect
        columnId={filterColumnId}
        placeholder="All Accounts"
      />
    </Root>
  )
}

export default Header
