/* eslint-disable @typescript-eslint/no-unsafe-return */
import { zodResolver } from '@hookform/resolvers/zod'
import type { FC } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { FaChevronLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { callToAction, footer } from '~/components/application-wizard/mixins'
import Button from '~components/button'
import { styled } from '~styles'
import { fieldHelp } from '~styles/mixins'
import { noop } from '~utils/functional'
import { formSchema } from './schema'
import UploadDocuments from './upload-documents'

export type FormValues = z.infer<typeof formSchema>

export interface UploadFormProps {
  defaultValues?: Partial<FormValues>
  onSubmit?: SubmitHandler<FormValues>
  isLoading?: boolean
  onBack?: () => void
}

const Form = styled.form`
  min-width: 600px;
`

const Footer = styled.div`
  ${footer}
`
const Help = styled.div`
  ${({ theme }) => fieldHelp(theme.danger)}
`
const CallToAction = styled(Button)`
  ${callToAction}
`
const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const GoToPrevious = styled.p`
  gap: 4px;
  font-size: 16px;
  color: #999999;
  align-items: center;
  display: flex;
  flex: 1;
  cursor: pointer;
`

const UploadForm: FC<UploadFormProps> = ({
  defaultValues,
  onSubmit,
  onBack = noop,
  ...props
}) => {
  const navigate = useNavigate()
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  const {
    formState: { isValid, submitCount },
    handleSubmit,
  } = methods

  const isFormValid = submitCount > 0 ? isValid : true

  const onFormSubmit = handleSubmit(data => {
    onSubmit?.(data)
    navigate('/application/complete')
  })

  return (
    <FormProvider {...methods}>
      <UploadDocuments />
      <Form noValidate onSubmit={onFormSubmit} {...props}>
        <Footer>
          {!isFormValid && (
            <Help css={{ marginBottom: 10 }}>
              Please correct any errors before continuing.
            </Help>
          )}
          <ButtonsContainer>
            <GoToPrevious onClick={onBack}>
              <FaChevronLeft /> Previous step
            </GoToPrevious>
            <CallToAction isDisabled={!isFormValid} variant="primary">
              Save & Continue
            </CallToAction>
            <div css={{ flex: 1 }} />
          </ButtonsContainer>
        </Footer>
      </Form>
    </FormProvider>
  )
}

export { UploadForm }
