import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Loading from '~components/loading'
import { Stepper, Step } from '~components/stepper'
import { styled } from '~styles'
import { AccountStep } from './account-step'
import { AccountHolderStep } from './account-holder-step'
import { ApplicationLayout } from './application-layout'
import { Devtools } from './devtools'
import { ReviewStep } from './review-step'
import { ApplicationStep } from './types'
import { VerifyStep } from './verify-step'
import { useLeadQuery } from '~api/lead'
import { ReviewDisclosure } from './disclosures/review-disclosure'
import { VerifyDisclosure } from './disclosures/verify-disclosure'

const Em = styled.span`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
`
const ErrorText = styled.div`
  color: ${({ theme }) => theme.danger};
`

const steps: ApplicationStep[] = [
  {
    component: AccountStep,
    isComplete: false,
    label: 'Your Account Info',
    title: (
      <>
        Take Control Of Your Retirement In Just <Em>3 Minutes</Em>
      </>
    ),
    disclosure: '',
  },
  {
    component: AccountHolderStep,
    isComplete: false,
    label: 'Your Information',
    title: (
      <>
        Our Goal is to Help You Maximize Your Retirement Growth in a{' '}
        <Em>Tax-Advantaged IRA</Em>
      </>
    ),
    disclosure: '',
  },
  {
    component: ReviewStep,
    isComplete: false,
    label: 'Review Info',
    title: (
      <>
        You're Almost Done! <Em>Submit Today!</Em>
      </>
    ),
    disclosure: <ReviewDisclosure />,
  },
  {
    component: VerifyStep,
    isComplete: false,
    label: 'Verify Your Identity',
    title: (
      <>
        Before We Transfer Funds <Em>Let's Get You Verified</Em>
      </>
    ),
    disclosure: (
      <>
        <VerifyDisclosure />
        <ReviewDisclosure />
      </>
    ),
  },
]

const scrollToTop = (): void => window.scrollTo(0, 0)

const ApplicationWizard = () => {
  const [searchParams] = useSearchParams()
  const isDebug = !!searchParams.get('debug')

  const { data: lead, isError, error, isLoading } = useLeadQuery()
  const [step, setStep] = useState(0)
  const { title, component: ActiveStep, disclosure } = steps[step]

  const handleBack = (): void => {
    step > 0 && setStep(step - 1)
    scrollToTop()
  }

  const handleNext = (): void => {
    step < steps.length - 1 && setStep(step + 1)
    steps[step]['isComplete'] = true
    scrollToTop()
  }

  const handleReset = (): void => {
    setStep(0)
    scrollToTop()
  }

  return (
    <ApplicationLayout title={title} disclosure={disclosure}>
      {steps.length !== step + 1 && (
        <Stepper activeIndex={step} css={{ marginBottom: 40 }}>
          {steps.map(({ label, isComplete }, idx) => (
            <Step index={idx} isComplete={isComplete} key={label}>
              {label}
            </Step>
          ))}
        </Stepper>
      )}
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <ErrorText role="alert">{(error as Error)?.message}</ErrorText>
      ) : (
        <ActiveStep
          lead={lead}
          onBack={handleBack}
          onNext={handleNext}
          onReset={handleReset}
        />
      )}
      {isDebug && (
        <Devtools
          onBack={handleBack}
          onNext={handleNext}
          onReset={handleReset}
        />
      )}
    </ApplicationLayout>
  )
}

export { ApplicationWizard }
