import { FC, createContext, useContext, useMemo } from 'react'
import styled from '@emotion/styled'

export interface StepperProps {
  activeIndex?: number
}

type State = {
  activeIndex: number
}

const Context = createContext<State | undefined>(undefined)

const Root = styled.div`
  display: flex;
`

const Stepper: FC<StepperProps> = ({ activeIndex = 0, ...props }) => {
  const value = useMemo(() => ({ activeIndex }), [activeIndex])

  return (
    <Context.Provider value={value}>
      <Root {...props} />
    </Context.Provider>
  )
}

function useStepper(): State {
  const state = useContext(Context)

  if (!state) {
    throw new Error('useStepper must be used within a Stepper')
  }

  return state
}

export { Stepper, useStepper }
