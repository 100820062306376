import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useSendPlatformEvent } from '~api/platform-events'
import { PoweredByDT } from '~assets/icons'
import { LeadMilestones } from '~models/leads/lead-milestones'
import Arrow from './arrow.svg'

export interface Props {
  leadMilestones: LeadMilestones
  theme?: string
}

export function OpenAccount({ leadMilestones, theme }: Props) {
  const navigate = useNavigate()
  const { mutate: sendEvent } = useSendPlatformEvent()

  if (leadMilestones.leadMilestonesStatus.ApplicationSubmitted) {
    return null
  }

  if (theme === 'msdira') {
    return (
      <div className="relative flex h-60 flex-1 flex-col rounded-lg bg-gradient-to-tr from-[#27820C] to-[#3EAE1D] px-5 py-7 text-white md:h-48 md:flex-row">
        <div className="mb-4 flex h-full flex-col justify-between leading-6 md:mb-0">
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold">Easy. Fast. Secure.</h1>
            <p>Create an account in just 3 minutes.</p>
          </div>
          <PoweredByDT />
        </div>
        <div className="mt-2 flex flex-1 items-end md:mt-0 md:justify-center">
          {leadMilestones.leadMilestonesStatus.ApplicationStarted ? (
            <button
              className="flex h-12 w-52 flex-col items-center justify-center rounded-md border-2 border-white bg-[#0B50CA] font-bold text-white"
              onClick={() => navigate('/application')}
            >
              <span>Complete Application</span>
            </button>
          ) : (
            <button
              className="flex h-12 w-52 flex-col items-center justify-center rounded-md border-2 border-white bg-[#0B50CA] font-bold text-white"
              onClick={() => {
                sendEvent('application_started')
                navigate('/application')
              }}
            >
              <span>Open Account</span>
            </button>
          )}
        </div>
        <Arrow className="absolute right-1/2 top-20 hidden md:block" />
      </div>
    )
  }
  return (
    <div className="relative flex h-60 flex-1 flex-col rounded-lg bg-[#0B50CB] px-5 py-7 text-white md:h-48 lg:flex-row">
      <div className="mb-4 flex h-full flex-col justify-between leading-6 md:mb-0">
        <div className="flex flex-col">
          <h1 className="text-3xl font-bold">Easy. Fast. Secure.</h1>
          <p>Create an account in just 3 minutes.</p>
        </div>
      </div>
      <div className="mt-22 flex flex-1 items-end md:mt-0 lg:justify-center">
        {leadMilestones.leadMilestonesStatus.ApplicationStarted ? (
          <button
            className="flex h-12 w-52 flex-col items-center justify-center rounded-md border-2 border-white bg-white font-bold text-[#0B50CB]"
            onClick={() => navigate('/application')}
          >
            <span>Complete Application</span>
          </button>
        ) : (
          <button
            className="flex h-12 w-52 flex-col items-center justify-center rounded-md border-2 border-white bg-white font-bold text-[#0B50CB]"
            onClick={() => {
              sendEvent('application_started')
              navigate('/application')
            }}
          >
            <span>Open Account</span>
          </button>
        )}
      </div>
      <Arrow className="absolute right-1/2 top-20 hidden lg:block" />
    </div>
  )
}
